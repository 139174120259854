/**
 * Show inline gallery-viewer
 */

vl.gallery = {};
vl.gallery.dress;
vl.gallery.dressAll;

(function () {

  var galleryClass            = 'js-' + vl.ns + 'gallery',
      galleryViewerClass      = 'js-' + vl.ns + 'gallery__viewer',
      galleryViewerImgClass   = vl.ns + 'gallery__viewer__img',
      galleryItemSelector     = '[data-'+ vl.ns + 'viewer-item]',
      galleryBoundClass       = 'js-' + vl.ns + 'gallery-bound';

  vl.gallery.dress = function(gallery) {
    addClass(gallery, galleryBoundClass);
    // get all gallery triggers for this specific gallery
    var items = gallery.querySelectorAll(galleryItemSelector);

    // create image and set first as active
    var viewer = gallery.getElementsByClassName(galleryViewerClass)[0];
    var img = document.createElement('img');
    addClass(img, galleryViewerImgClass);    
    setImage(img, items[0]);
    viewer.appendChild(img);

    [].forEach.call(items, function (item) {
      // bind click event
      item.addEventListener('click', function (e) {
        e.preventDefault();
        // set source of viewer 
        setImage(img, item);
      });
    });

    function setImage(target, source) {
        img.setAttribute('src', '');
        img.setAttribute('src', source.getAttribute('href'));
    }
    
  };

  // dress all galleries
  vl.gallery.dressAll = function() {

    var elements = document.querySelectorAll('.' + galleryClass + ':not(.' + galleryBoundClass + ')');

    [].forEach.call(elements, function (element) {
      vl.gallery.dress(element);
    });
  };

  // Initiate
  vl.gallery.dressAll();

  
})();
