/**
 * Wizard
 **/
vl.wizard = {};
vl.wizard.dress;

(function () {
  var wizClass      = 'js-' + vl.ns + 'wizard',
      wizBoundClass = wizClass + '-bound',
      wizPanesClass = vl.ns + 'wizard__panes',
      wizPaneClass  = vl.ns + 'wizard__pane',
      pbClass       = vl.ns + 'progress-bar',
      wizNextAtt    = 'data-' + vl.ns + 'wizard-next',
      wizPrevAtt    = 'data-' + vl.ns + 'wizard-prev',
      wizStepsAtt   = 'data-' + vl.ns + 'wizard-step',
      wizIndexAtt   = 'data-' + vl.ns + 'index';

  vl.wizard.dress = function (wizard, params) {
    addClass(wizard, wizBoundClass);

    var paneWrapper = wizard.getElementsByClassName(wizPanesClass)[0],
        panes       = wizard.getElementsByClassName(wizPaneClass),
        nPanes      = panes.length, // 1e element is "0"
        curPane     = 1,
        ctasNext     = wizard.querySelectorAll('['+ wizNextAtt +']'),
        ctasPrev     = wizard.querySelectorAll('['+ wizPrevAtt +']'),
        progressbar = wizard.getElementsByClassName(pbClass)[0],
        pbSteps     = wizard.querySelectorAll('['+ wizStepsAtt +']');

    var flkty = new Flickity( paneWrapper, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      draggable: false,
      accessibility: false
    });

    var flkViewport    = wizard.querySelector('.flickity-viewport');

    var k = 1;
    [].forEach.call(pbSteps, function(step){
      step.setAttribute(wizIndexAtt, k);
      k++;

      step.addEventListener('click', function(e){

        e.preventDefault();
        var index = step.getAttribute(wizIndexAtt);

        params.callbackFn(curPane, index, returnFn);
        function returnFn(isValid){
          if(isValid){
            curPane = index;
            setProgressbar();
          }
        }
      });
    });

    // Next button
    if(ctasNext){

      [].forEach.call(ctasNext, function(ctaNext){
        ctaNext.addEventListener('click', function(e){
          e.preventDefault();

          if(curPane < nPanes){
            params.callbackFn(curPane, (curPane + 1), returnFn);
            function returnFn(isValid){
              if(isValid){
                curPane++;
                setProgressbar();
              }
            }
          }
        });
      });
    };

    // Prev button
    if(ctasPrev){
      [].forEach.call(ctasPrev, function(ctaPrev){
        ctaPrev.addEventListener('click', function(e){
          e.preventDefault();

          if(curPane > 0){
            params.callbackFn(curPane, (curPane - 1), returnFn);
            function returnFn(isValid){
              if(isValid){
                curPane--;
                setProgressbar();
              }
            }
          }
        });
      });
    };

    function setProgressbar(){
      flkty.select( (curPane -1), false, true );
      vl.progressbar.updateStep(progressbar, curPane);
      // 200 ms delay = animation time
      setAdaptiveHeight();
    };

    flkty.on( 'cellSelect', function() {
      setPaneVisibility();
    });

    // Check pane visibility and set a class "not-selected" to hide it
    function setPaneVisibility(){
      [].forEach.call(panes, function(pane){
        removeClass(pane, 'not-selected');
        if(!hasClass(pane, 'is-selected')){
          addClass(pane, 'not-selected');
        }
      });
    };
    setPaneVisibility();

    function setAdaptiveHeight(){
      // Adaptiveheight is not supported in v1 of flickity, so simulate it
        var activePane = wizard.querySelector('.is-selected');
        var activePaneHeight = activePane.offsetHeight;
        flkViewport.style.height = activePaneHeight + "px";
    };

    window.addEventListener('resize', function(event){
      window.setTimeout(function(){
        setAdaptiveHeight();
      }, 200);
    });

    setAdaptiveHeight();
  };

})();
