/**
 *
 * Tabs JS enhancements
 *
**/

// create global vl tabs object & functions
vl.tabs = {};
vl.tabs.dress;
vl.tabs.dressAll;

(function () {

  var tabActiveClass      = vl.ns + 'tab--active',
      tabsWrapperClass    = vl.ns + 'tabs__wrapper',
      tabsToggleClass     = vl.ns + 'tabs__toggle',
      tabClass            = vl.ns + 'tab',
      tabsBoundClass      = 'js-' + vl.ns + 'tabs-bound',
      dataTabList         = '[data-' + vl.ns + 'tabs-list]',
      dataTab             = '[data-' + vl.ns + 'tab]',
      dataTabPane         = '[data-' + vl.ns + 'tab-pane]',
      tabToggleAtt        = 'data-' + vl.ns + 'tabs-toggle'
      dataRespToggle      = '['+ tabToggleAtt +']',
      dataRespToggleLabel = '[data-' + vl.ns + 'tabs-toggle] span',
      tabIndexAtt         = 'data-' + vl.ns + 'index',
      tabActiveAtt        = 'data-' + vl.ns + 'active',
      tabShowAtt          = 'data-' + vl.ns + 'show',
      tabCloseAtt         = 'data-' + vl.ns + 'close',
      tabRespLabelAtt     = 'data-' + vl.ns + 'tabs-responsive-label',
      tabsAtt             = 'data-' + vl.ns + 'tabs',
      gI = 0; // Global index used for creating unique tabs when ID isn't present

  vl.tabs.dress = function(tabsContainer) {
    addClass(tabsContainer, tabsBoundClass);

    // get all tabs in wrapper
    var tabsList = tabsContainer.querySelector(dataTabList);
    var tabs = tabsContainer.querySelectorAll(dataTab);
    var tabPanes = tabsContainer.querySelectorAll(dataTabPane);
    var tabsWrapper = tabsContainer.querySelector('.' + tabsWrapperClass);

    // Generate responsive toggle | Assign attributes
    var arrToggle = generateResponsiveToggle(tabsContainer, tabsList);
    var toggle = arrToggle[0];
    var toggleLbl = arrToggle[1];

    // Generate tab attributes
    generateTabsAttributes(tabsWrapper, tabsList, tabs, tabPanes, toggleLbl);

    // Loop through tabs in wrapper
    [].forEach.call(tabs, function(tab){
      // detect click event on tab
      tab.addEventListener('click', function(e){
        e.preventDefault();
        if(!hasClass(tab.closest('.' + tabClass), tabActiveClass)){
          // Url w. hash
          window.location = '#' + tab.id;

          // Remove active class from all elements
          [].forEach.call(tabs, function(tab){
            var parent = tab.closest('.' + tabClass);
            if(hasClass(parent, tabActiveClass)){
              removeClass(parent, tabActiveClass);
              tab.setAttribute('aria-selected','false');
            }
          });

          // Get parent (<li> item) of selected tab
          var parent = tab.closest('.' + tabClass);
          addClass(parent, tabActiveClass);
          tab.setAttribute('aria-selected','true');

          // Reset active states of all panes
          resetPaneState(tabPanes);

          // toggle active state of all panes
          togglePaneState(tabsContainer, tab.id);

          // Add correct label to (responsive) toggle button
          toggleLbl.innerHTML = tab.innerHTML;

          // Trigger click on close button
          setResponsiveTabsState(tabsList, false, true);
          setToggleState(toggle, false, false);
        }
      });

      // Detect arrow & spacebar usage on tab
      tab.addEventListener('keydown', function(e){
        switch(e.keyCode){
          // Left arrow
          case 37: case 38:
          var index = tab.getAttribute(tabIndexAtt);
          if(index > 0){
            index--;
            var el = tabsList.querySelector('['+tabIndexAtt+'="'+ index +'"]');
            el.click();
          }
          break;
          // Right arrow
          case 39: case 40:
          var index = tab.getAttribute(tabIndexAtt);
          if(index < (tabs.length - 1)){
            index++;
            var el = tabsList.querySelector('['+ tabIndexAtt +'="'+ index++ +'"]');
            el.click();
          }
          break;
        }
      });
    });

    if(tabsContainer.hasAttribute(tabActiveAtt)){
        var activeTab = tabsContainer.querySelector('#' + tabsContainer.getAttribute(tabActiveAtt));
        if(activeTab !== null){
          activeTab.click();
        }else{
          console.warn(vl.warnings.default.tabs.dataActive);
        }
    }
  };

  /*
  * resetPaneState()
  * tabPanes
  */
  function resetPaneState(tabPanes){
    // Loop through panes and disable the state (attributes & tabindex)
    [].forEach.call(tabPanes, function(elem){
      elem.setAttribute('aria-hidden',true);
      elem.setAttribute(tabShowAtt,false);
      elem.setAttribute('tabindex','-1');
    });
  }

  /*
  * togglePaneState()
  * tabsContainer, dataId
  */
  function togglePaneState(tabsContainer, tabId){
    //  select all contentblocks based on their aria attribute
    var pane = tabsContainer.querySelector(dataTabPane + '[aria-labelledby="' + tabId + '"]');
    // Set attributes & tabindex
    pane.setAttribute('aria-hidden','false');
    pane.setAttribute(tabShowAtt,'true');
    pane.removeAttribute('tabindex');
  }

  /*
  * setToggleState()
  * toggle, data-close, aria-expanded
  */
  function setToggleState(toggle, dataClose, ariaExpanded){
    toggle.setAttribute(tabCloseAtt, dataClose);
    toggle.setAttribute('aria-expanded', ariaExpanded);
  }

  /*
  * setResponsiveTabsState()
  * tabs, data-show, aria-hidden
  */
  function setResponsiveTabsState(tabsList, dataShow, ariaHidden){
    tabsList.setAttribute(tabShowAtt, dataShow);
    tabsList.setAttribute('aria-hidden', ariaHidden);
  }

  /*
  * generateResponsiveToggle()
  * Generate responsive button used in the dropdown on mobile views
  */
  function generateResponsiveToggle(tabsContainer, tabsList){

    var respToggle = document.createElement('button');
    respToggle.setAttribute('type','button');
    respToggle.setAttribute(tabToggleAtt,'');
    respToggle.setAttribute('aria-expanded','false');
    respToggle.setAttribute('aria-label','tabs toggle');
    addClass(respToggle, tabsToggleClass);
    insertAfter(respToggle, tabsList);

      // Span element w. title
      var respToggleTitle = document.createElement('span');

      if(tabsContainer.hasAttribute(tabRespLabelAtt))
        respToggleTitle.innerHTML = tabsContainer.getAttribute(tabRespLabelAtt);
      else
        respToggleTitle.innerHTML = "Navigatie";

      respToggle.appendChild(respToggleTitle);

    respToggle.addEventListener('click', function(e){
      if(respToggle.getAttribute(tabCloseAtt) === "true"){
        setResponsiveTabsState(tabsList, false, true);
        setToggleState(respToggle, false, false);
      }else{
        setResponsiveTabsState(tabsList, true, false);
        setToggleState(respToggle, true, true);
      }
    });

    return [respToggle, respToggleTitle];
  }

  /*
  * generateTabsAttributes()
  * Set aria tags, roles and other tags for accessibility
  * Check hash if available and match it to a tab, if present set tab active
  */
  function generateTabsAttributes(tabsWrapper, tabsList, tabs, tabPanes, toggleLbl){

    // Role & data attributes
    tabsList.setAttribute('role', 'tablist');

    [].forEach.call(tabsList.getElementsByClassName(tabClass), function(tabListItem){
      tabListItem.setAttribute('role','presentation');
    });

    // Tabs, tabPanes
    var activeElem = false;
    var i = 0;
    [].forEach.call(tabs, function(tab){
      tab.setAttribute('role','tab');

      var tabId = tab.id;
      var panelId = tabPanes[i].id;

      // If none generate tab id
      if(tabId == ""){
        tabId = "tab" + gI + "-" + i;
        tab.setAttribute('id', tabId);
      }

      // If none generate panel id
      if(panelId == ""){
        panelId = "panel" + gI + "-" + i;
        tabPanes[i].setAttribute('id', panelId);
      }

      tab.setAttribute('aria-controls', panelId);
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute(tabIndexAtt, i);
      tabPanes[i].setAttribute('id', panelId);
      tabPanes[i].setAttribute('role', 'tabpanel');
      tabPanes[i].setAttribute('aria-labelledby', tabId);
      tabPanes[i].setAttribute('aria-hidden', 'true');
      tabPanes[i].setAttribute(tabShowAtt, 'false');

      var listItem = tab.closest('.' + tabClass);
      // Check if active tab is present
      if(hasClass(listItem, tabActiveClass)){
        activeElem = true;
        tab.setAttribute('aria-selected', 'true');
        tabPanes[i].setAttribute('aria-hidden', 'false');
        tabPanes[i].setAttribute(tabShowAtt, 'true');
        toggleLbl.innerHTML = tab.innerHTML;
      }

      i++;
      gI++;
    });
  }

  vl.tabs.dressAll = function () {
    var wrappers = document.querySelectorAll('[' + tabsAtt + ']:not(.' + tabsBoundClass + ')');
    [].forEach.call(wrappers, function (tabsContainer) {
      vl.tabs.dress(tabsContainer);
    });
  };

  // Initiate
  vl.tabs.dressAll();

})();
