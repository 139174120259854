/**
 * Fix a range slider for the range element
 */

vl.input = vl.input || {};
vl.input.range = {};
vl.input.range.dress;
vl.input.range.dressAll;

(function () {

  var raClass       = 'js-' + vl.ns + 'range',
      raBoundClass  = raClass + '-bound',
      raSliderClass = raClass + '__slider',
      raFromClass   = raClass + '__from',
      raToClass   = raClass + '__to';


  vl.input.range.dress = function(element) {
    addClass(element, raBoundClass);

    var slider = element.getElementsByClassName(raSliderClass)[0],
        fromInput = element.getElementsByClassName(raFromClass)[0],
        toInput = element.getElementsByClassName(raToClass)[0];

    noUiSlider.create(slider, {
      start: [fromInput.value, toInput.value],
      step: 1,
      connect: true,
      range: {
        'min': parseInt(fromInput.value),
        'max': parseInt(toInput.value)
      }
    });

    // When the slider value changes, update the input and span
    slider.noUiSlider.on('update', function( values, handle ) {
      fromInput.value = parseInt(values[0]);
      toInput.value = parseInt(values[1]);
    });

    fromInput.addEventListener('change', function(){
      slider.noUiSlider.set([this.value, null]);
    });

    toInput.addEventListener('change', function(){
      slider.noUiSlider.set([null, this.value]);
    });
  };

  vl.input.range.dressAll = function() {

    var elements = document.querySelectorAll('.' + raClass + ':not(.' + raBoundClass + ')');

    [].forEach.call(elements, function (element) {
      vl.input.range.dress(element);
    });
  };

  // Initiate
  vl.input.range.dressAll();

})();
