/**
 * Show content in drawer
 */

vl.drawer = {};
vl.drawer.dress;
vl.drawer.dressAll;

(function () {

  var contentVisibleClass     = 'js-' + vl.ns + 'drawer__content--visible',
      drawerVisibleClass      = 'js-' + vl.ns + 'drawer--visible',
      closeClass              = 'js-' + vl.ns + 'drawer__close',
      smallClass              = 'js-' + vl.ns + 'drawer--small',
      linkedSliderClass       = 'js-' + vl.ns + 'drawer--linked-slider',
      activeItemClass         = 'js-' + vl.ns + 'drawer-active-item',
      defaultItemClass        = 'js-' + vl.ns + 'drawer-default-item',
      drawerBoundClass        = 'js-' + vl.ns + 'drawer-bound',
      drawerTipClass          = 'js-' + vl.ns + 'drawer__tip',
      dataPrefix              = 'data-' + vl.ns,
      drAtt                   = dataPrefix + 'drawer',
      drIDAtt                 = dataPrefix + 'drawer-id',
      drContentIDAtt          = dataPrefix + 'drawer-content-id',
      drContentAtt            = dataPrefix + 'drawer-content';

  vl.drawer.dress = function(drawer) {
    addClass(drawer, drawerBoundClass);
    // get all drawer triggers for this specific drawer
    var drawerID = drawer.getAttribute(drAtt);
    var linkedSlider = hasClass(drawer, linkedSliderClass);
    // add drawer tip
    var tip = document.createElement('div');
    tip.setAttribute('tabindex', -1);
    addClass(tip, drawerTipClass);

    drawer.insertBefore(tip, drawer.firstChild);

    // get every link which should toggle the drawer
    var links = document.querySelectorAll('['+drIDAtt+'="'+ drawerID +'"]');
    [].forEach.call(links, function (link) {
      link.setAttribute('aria-expanded', 'false');
      // bind click event
      link.addEventListener('click', function (e) {
        e.preventDefault();
        clickLink(link, drawer, tip, true);
      });

      if(hasClass(link, defaultItemClass)) {
        clickLink(link, drawer, tip, false);
      }
    });

    // Close the drawer when clicking close
    var closeLinks = drawer.getElementsByClassName(closeClass);
    [].forEach.call(closeLinks, function (close) {
      close.addEventListener('click', function(e){
        e.preventDefault();
        closeDrawer(drawer);
      });
    });
  };

  vl.drawer.dressAll = function() {

    var elements = document.querySelectorAll('['+drAtt+']:not(.' + drawerBoundClass + ')');

    [].forEach.call(elements, function (element) {
      vl.drawer.dress(element);
    });
  };

  // Initiate
  vl.drawer.dressAll();

  function clickLink(link, drawer, tip, scroll) {
    //  Check if that item is already active, if so, close the drawer
    if(hasClass(link, activeItemClass)) {
        closeDrawer(drawer);
    } else {
      var contentID = link.getAttribute(drContentIDAtt);
      var drawerContent = drawer.querySelectorAll('['+drContentAtt+'="'+ contentID +'"]')[0];
      // show drawer content
      if(drawerContent) {
        showDrawerContent(drawerContent, drawer, scroll, tip);
      }
      // make link active + place tip
      activateLink(link, tip, drawer);
    }
  }

  function showDrawerContent(drawerContent, drawer, scroll, tip) {
    var visible = hasClass(drawer, drawerVisibleClass);


    if(visible){
      hideDrawerContent(drawer, false);
    }else{
      // trigger page resize for the content of the drawer
      fireEvent(window, 'resize');
    }

    // show drawer
    addClass(drawer, drawerVisibleClass);
    // show content
    addClass(drawerContent, contentVisibleClass);

    if(!visible && scroll) {
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      // scroll to drawer
      var scrollposition = (document.body.scrollTop + drawer.getBoundingClientRect().top) - h/4;
      scrollTo(document.body, scrollposition, 300, function(){ tip.focus(); });
    }else if (visible && scroll) { scrollposition = document.body.scrollTop;
      tip.focus();
      document.body.scrollTop = scrollposition;
    }
  }

  function activateLink(link, tip, drawer) {
    addClass(link, activeItemClass);
    link.setAttribute('aria-expanded', 'true');
    var rect = link.getBoundingClientRect(),
      left = rect.width/2,
      drawerRect = drawer.getBoundingClientRect();
    // get link position
    if(hasClass(drawer, smallClass)) {
      left += rect.left - drawerRect.left;
    }else{
      left += rect.left;
    }
    left = Math.max(0, Math.min(left, (drawerRect.right - tip.getBoundingClientRect().width)));


    tip.style.left = left + 'px';
  }

  function closeDrawer(drawer) {
    removeClass(drawer, drawerVisibleClass);
    hideDrawerContent(drawer, true);
  }

  function hideDrawerContent(drawer, scrollto) {
    var drawerID = drawer.getAttribute(drAtt);
    // Hide other visible drawer contents
    var visibleContent = drawer.getElementsByClassName(contentVisibleClass)[0];
    if(visibleContent) {
      removeClass(visibleContent, contentVisibleClass);
    }
    // make other links unactive
    var link = document.querySelectorAll('['+drIDAtt+'="'+ drawerID +'"].' + activeItemClass)[0];
    if(link){
      removeClass(link, activeItemClass);
      link.setAttribute('aria-expanded', 'false');
      if(scrollto) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        // scroll to drawer
        var scrollposition = (document.body.scrollTop + link.getBoundingClientRect().top) - h / 4;
        scrollTo(document.body, scrollposition, 300, function () {
          link.focus();
        });
      }
    }
  }
})();
