/**
 * Refresh breakpoints
 */

vl.breakpoint = {};
vl.breakpoint.refreshValue;

(function(){

  vl.breakpoint.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');

  // transfer css breakpoints to JS
  vl.breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
  };

  /**
   * Add eventlisteners to window
   */
  window.addEventListener('resize', function(){
    vl.breakpoint.refreshValue();
  });

})();
