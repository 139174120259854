/**
 * Create a tag input by data-attribute
 */
vl.input = vl.input || {};
vl.input.taginput = {};
vl.input.taginput.dress;
vl.input.taginput.dressAll;

(function () {
  var tiClass = 'js-' + vl.ns + 'tag-input',
      tiBoundClass = tiClass + '-bound',
      tiWrapperClass = vl.ns + 'tag-input-wrapper';

  /*
 * vl.input.taginput.dress(field)
 * Initiates all taginputs in the DOM
 * @param 1: ".js-tag-input" DOM element
 */
  vl.input.taginput.dress = function (field) {
    addClass(field, tiBoundClass);
    // add div to show tag input
    var tagDiv = document.createElement('div');
    insertAfter(tagDiv, field);
    addClass(tagDiv, tiWrapperClass);
    addClass(tagDiv, vl.ns + 'input-field');
    addClass(tagDiv, vl.ns + 'input-field--block');

    var name = field.name;
    var placeholder = field.placeholder;

    var taggleSettings = {
      containerFocusClass: vl.ns + 'input-field--focus',
      hiddenInputName: name + '[]',
      placeholder: placeholder,
      tabIndex: 0,
      duplicateTagClass: 'taggle--double'
    };

    removeElement(field);

    new Taggle(tagDiv, taggleSettings);
  };

  /*
 * vl.input.taginput.dressAll()
 * Initiates all taginputs in the DOM
 */
  vl.input.taginput.dressAll = function () {
    var elements = document.querySelectorAll('.' + tiClass + ':not(.' + tiBoundClass + ')');
    [].forEach.call(elements, function (element) {
      vl.input.taginput.dress(element);
    });
  };

  // Initiate
  vl.input.taginput.dressAll();

})();
