vl.warnings = {
  default: {
    autocomplete: {
      geolocation: 'HTML5 Geolocation only works in a secure environment',
      geolocationCallback: 'Specify a correct parameter ("postal_code","city","formatted_address") or fuction for geolocation (parameter geolocation.return)'
    },
    drilldown: {
      search: 'Search warning in subcolumn. In order to apply the search bar make sure a subcolumn header (.drilldown__subcolumn__header) is present inside the subcolumn.'
    },
    tabs: {
      dataActive: 'data-active attribute doesn\'t match an ID selector in the list of tabs.',
    }
  },
  v2_2_1: {
    showOnCheckedAll: "vl.input.showOnCheckedAll is deprecated since v2.2.1. Use vl.input.showOnChecked.dressAll() instead. More information on https://overheid.vlaanderen.be/webuniversum/versies/2.2.1#show-on-checked",
    showOnSelectAll: "vl.select.showOnSelectAll is deprecated since v2.2.1. Use vl.select.showOnSelect.dressAll() instead. More information on https://overheid.vlaanderen.be/webuniversum/versies/2.2.1#show-on-select"
  }
};
