vl.progressbar = {};
vl.progressbar.updateStep;

(function(){

  var pbStepClass       = vl.ns + 'progress-bar__step',
      pbStepActiveClass = vl.ns + 'progress-bar__step--active';

  vl.progressbar.updateStep = function(progressbar, stepIndex){
    var steps   = progressbar.querySelectorAll('.' + pbStepClass),
        nSteps  = steps.length;

    [].forEach.call(steps, function(step){
      removeClass(step, pbStepActiveClass);
      addClass(steps[(stepIndex - 1)], pbStepActiveClass);
    });
  };
})();

