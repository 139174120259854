/**
 * Rotate the lines according to the scroll position
 */
(function () {

  var chClass               = 'js-' + vl.ns + 'content-header',
      ch2Class              = vl.ns + 'content-header',
      chContentClass        = chClass + '__content',
      chLine2Class          = chClass + '__line-2',
      chLine3Class          = chClass + '__line-3',
      contentheadercontent  = document.getElementsByClassName(chContentClass)[0];

  if (!contentheadercontent){
    return;
  }

  var header = contentheadercontent.closest('.' + ch2Class),
    line2 = header.getElementsByClassName(chLine2Class)[0],
    line3 = header.getElementsByClassName(chLine3Class)[0],
    headerheight = header.getBoundingClientRect().height,
    latestKnownScrollY = window.pageYOffset,
    ticking = false,
    // randomly rotate the lines by default
    line2rotate = randomIntFromInterval(25, 35),
    line3rotate = randomIntFromInterval(40, 50);

  if (!header || !line2 || !line3) {
    return;
  }

  function onScroll() {
    latestKnownScrollY = window.pageYOffset;
    if(latestKnownScrollY < headerheight) {
      requestTick();
    }
  }

  function requestTick() {
    if (!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }

  function update() {

    // reset the tick so we can
    // capture the next onScroll
    ticking = false;

    var percentage = (latestKnownScrollY / headerheight);
    // rotate lines according to scrolled height
    var line2tr = percentage * line2rotate;
    line2.style['transform'] = "rotate(" + line2tr + "deg) translateY(" + Math.trunc(latestKnownScrollY / 2) + "px)";
    var line3tr = percentage * line3rotate;
    line3.style['transform'] = "rotate(" + line3tr + "deg) translateY(" + Math.trunc(latestKnownScrollY / 4) + "px)";
  }

  window.addEventListener('scroll', onScroll, false);
  requestTick();

})();
