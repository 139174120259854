/**
 * Bind the dynamic input
 */

vl.dynamicinput = {};
vl.dynamicinput.dress;
vl.dynamicinput.dressAll;

(function () {

  var diClass       = 'js-' + vl.ns + 'dynamic-label',
      diBoundClass  = 'js-' + vl.ns + 'dynamic-label-bound',
      diValueClass  = 'js-' + vl.ns + 'dynamic-label__value',
      diFieldClass  = 'js-' + vl.ns + 'dynamic-label__field',
      diActiveClass =         vl.ns + 'dynamic-label--active',
      diToggleClass = 'js-' + vl.ns + 'dynamic-label__toggle',
      diErrorAtt    = 'data-' + vl.ns + 'has-error';

  vl.dynamicinput.dress = function(element){
    addClass(element, diBoundClass);

    // get all variables
    var label = element.getElementsByClassName(diValueClass)[0],
        value = label.innerHTML,
        input = element.getElementsByClassName(diFieldClass)[0],
        toggle = element.getElementsByClassName(diToggleClass)[0];

    // set input value
    input.value = value;

    // add toggle event on clicking the icon
    toggle.addEventListener('click', toggleInput);
    // toggle on esc / enter in the input field
    input.addEventListener('keydown', function(e){
      if (e.keyCode === 13) {
        e.preventDefault();
        // move focus to
        fireEvent(input, 'blur');
        // check if it is valid
        if(input.getAttribute(diErrorAtt) !== "true"){
          // On enter transfer the value
          label.innerHTML = this.value;
          toggleInput();
        }
      }
      // on esc
      if(e.keyCode === 27) {
        e.preventDefault();
        this.value = label.innerHTML;
        toggleInput();
      }
    });

    // function to activate the input
    function toggleInput() {
      var activeClass = diActiveClass;

      if(hasClass(element, activeClass)){
        removeClass(element, activeClass);
      }else{
        addClass(element, activeClass);
        input.select();
      }
    }
  };

  vl.dynamicinput.dressAll = function() {
    var elements = document.querySelectorAll('.' + diClass + ':not(.' + diBoundClass + ')');
    [].forEach.call(elements, function (element){
      vl.dynamicinput.dress(element);
    });
  }

  // Initiate
  vl.dynamicinput.dressAll();

})();
