/**
 * Show container when specific option is selected in select attribute
 */

vl.select = vl.select || {};
vl.select.showOnSelect = {};
vl.select.showOnSelect.dress;
vl.select.showOnSelect.dressAll;

vl.select.showOnSelectAll; // Deprecated

 (function () {
  var sosClass = 'js-' + vl.ns + 'show-on-select',
      sosBoundClass = sosClass + '-bound',
      sosIDAtt = 'data-' + vl.ns + 'id',
      sosAtt = 'data-' + vl.ns + 'show-on-select',
      sosOptionAtt = sosAtt + '-option',
      sosTargetAtt = 'data-' + vl.ns + 'target',
      sosWrapperAtt = sosAtt + '-wrapper',
      sosContentAtt = sosAtt + '-content',
      sosShowAtt = 'data-' + vl.ns + 'show',
      sosHookAtt = 'data-' + vl.ns + 'hook';


  /**
   * dress function for show-on-select
   * @param  {DOM element} soswrapper [element with data attribute "data-show-on-select-wrapper"]
   * @return {false}
   */
  vl.select.showOnSelect.dress = function(soswrapper) {
    addClass(soswrapper, sosBoundClass);

    // Assign ID if none is available
    if(soswrapper.getAttribute(sosIDAtt) == null)
        soswrapper.setAttribute(sosIDAtt, 'show-on-select-' + uniqueId());

    // get all selects in wrapper
    var sosSelects = soswrapper.querySelectorAll('['+ sosAtt +']');

    // Loop through selects in wrapper
    [].forEach.call(sosSelects, function(select){

      // detect change in select elem
      select.onchange = function(e){
        var dataId = soswrapper.getAttribute(sosIDAtt);
        var selectedOpt = select.options[select.selectedIndex];
        resetContent(dataId, soswrapper);
        if(selectedOpt.hasAttribute(sosOptionAtt)){
          if(selectedOpt.hasAttribute(sosTargetAtt)){
            var dataTarget = selectedOpt.getAttribute(sosTargetAtt);
            showContent(dataTarget, soswrapper);
          }
        }
      };

    });
  };

  /**
   * dressAll function to dress all show-on-select components at once
   * @return {false}
   */
  vl.select.showOnSelect.dressAll = function() {
    var wrappers = document.querySelectorAll('['+ sosWrapperAtt +']:not(.' + sosBoundClass + ')');

    [].forEach.call(wrappers, function (soswrapper) {
      vl.select.showOnSelect.dress(soswrapper);
    });
  };

  /**
   * DEPRECATED SINCE v2.3.0 - use vl.select.showOnSelect.dressAll() instead
   * dressAll function to dress all show-on-select components at once
   * @return {false}
   */
  vl.select.showOnSelectAll = function () {
    console.warn(vl.warnings.v2_2_1.showOnSelectAll);
    vl.select.showOnSelect.dressAll();
  };

  // Initiate
  vl.select.showOnSelect.dressAll();

 /*
  * resetContent()
  * dataId: dataId of selected option
  */
  function resetContent(dataId, soswrapper){
    //  select all contentblocks based on their data-id attribute
    var contentblocks = soswrapper.querySelectorAll('[' + sosIDAtt + '="'+ dataId +'"] [' + sosContentAtt + ']');

    // Loop through content blocks
    [].forEach.call(contentblocks, function(elem){
      // Set attributes & tabindex
      elem.setAttribute('aria-hidden','true');
      elem.setAttribute(sosShowAtt,'false');
      elem.setAttribute('tabindex','-1');
    });
  }

 /*
  * showContent()
  * dataTarget: dataTarget of selected option
  */
  function showContent(dataTarget, soswrapper){
    //  select contentblock based on their data-hook attribute
    var contentblock = soswrapper.querySelector('[' + sosContentAtt + '][' + sosHookAtt + '="'+ dataTarget +'"]');

    // Set attributes & tabindex
    contentblock.setAttribute('aria-hidden','false');
    contentblock.setAttribute(sosShowAtt,'true');
    if(contentblock.hasAttribute('tabindex'))
      contentblock.removeAttribute('tabindex');
  }

})();
