/**
 * Progressively enhance a select field
 */

// create global vl select function
vl.select = vl.select || {};
vl.select.dress;
vl.select.dressAll;
vl.select.setDisabledState;

(function () {

  var sClass            = 'js-' + vl.ns + 'select',
      sBoundClass       = sClass + '-bound',
      sInputClass       = sClass + '__input',
      sCtaClass         = vl.ns + 'select__cta',
      sCtaActiveClass   = vl.ns + 'select__cta--active',
      sCtaTitleClass    = vl.ns + 'select__cta__title',
      sItemClass        = vl.ns + 'select__item',
      sGroupClass       = vl.ns + 'select__group',
      sWrapperClass     = vl.ns + 'select__wrapper',
      sFormClass        = vl.ns + 'select__form',
      sListClass        = vl.ns + 'select__list',
      sListWrapperClass = vl.ns + 'select__list-wrapper',
      sEmptyClass       = vl.ns + 'select__empty',
      lastSelectId,
      lastContainer,
      dfNoResultsFound  = 'Geen resultaten',
      sAtt              = 'data-' + vl.ns + 'select',
      sIDAtt            = 'data-' + vl.ns + 'id',
      sContentAtt       = 'data-' + vl.ns + 'content',
      sInputAtt         = 'data-' + vl.ns + 'input',
      sRecordsAtt       = 'data-' + vl.ns + 'records',
      sFocusAtt         = 'data-' + vl.ns + 'focus',
      sValidateAtt      = 'data-' + vl.ns + 'validate-form',
      sShowAtt          = 'data-' + vl.ns + 'show',
      sSelectedAtt      = 'data-' + vl.ns + 'selected',
      sIndexAtt         = 'data-' + vl.ns + 'index',
      sRecordAtt        = 'data-' + vl.ns + 'record',
      sLabelAtt         = 'data-' + vl.ns + 'label',
      sPlaceholderAtt   = 'data-' + vl.ns + 'placeholder',
      sValueAtt         = 'data-' + vl.ns + 'value',
      sErrPlaceholderAtt= 'data-' + vl.ns + 'error-placeholder',
      sErrMsgAtt        = 'data-' + vl.ns + 'error-message',
      sErrClassAtt      = 'data-' + vl.ns + 'error-class',
      sErrIDAtt         = 'data-' + vl.ns + 'error-id',
      sHasErrAtt        = 'data-' + vl.ns + 'has-error',
      sEmptyAtt         = 'data-' + vl.ns + 'empty';

  vl.select.dress = function(selectField, params) {
    addClass(selectField, sBoundClass);
    /*
    * Variables needed in Generate selects basted on original <select> elements
    */
    var arr                       = generateSelect(selectField),
        arrOptions                = arr[0],
        selectId                  = arr[1],
        selectContainer           = arr[2],
        selectList                = arr[3],
        originalSelectOption      = null,
        activeArrOptions          = arrOptions,
        selectDummyInput          = selectContainer.getElementsByClassName(sInputClass)[0],
        selectContent             = selectContainer.querySelector('[' + sContentAtt + ']'),
        selectContentInput        = selectContent.querySelector('['+ sInputAtt +']'),
        selectContentList         = selectContent.querySelector('['+ sRecordsAtt +']'),
        selectFocusElems          = selectContainer.querySelectorAll('['+ sFocusAtt +']'),
        selectForm                = selectField.closest('['+ sValidateAtt +']') || null;

    /*
    * Events in select element
    */
    (selectContentInput ? selectContentInput.addEventListener('keyup', selectContentInputKeyUpHandler) : null );
    (selectContentInput ? selectContentInput.addEventListener('keydown', selectContentInputKeyDownHandler) : null );
    (selectContentInput ? selectContentInput.addEventListener('blur', selectContentInputBlurHandler) : null );
    (selectContainer ? selectContainer.addEventListener('keydown', selectContainerKeyDownEventHandler) : null );
    (selectDummyInput ? selectDummyInput.addEventListener('click', selectDummyInputClickEventHandler) : null );

    document.addEventListener('click', function(e){
      setSelectState(false);
    });

    selectContainer.addEventListener('click', function(e){
      e.stopPropagation();
      e.preventDefault();
    });

    /*
    * Eventhandler | selectContentInput keyDown
    */
    function selectContentInputKeyDownHandler(e){
      if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        return false;
      }

      switch(e.keyCode){
        case 27: case 9:
          e.preventDefault();
          return false;
        break;
      }
    };

    /*
    * Eventhandler | selectContentInput keyUp
    */
    function selectContentInputKeyUpHandler(e){
      e.preventDefault();
      if(e.shiftKey && e.keyCode == 9){ // Tab + Shift
        setSelectState(false);
        selectDummyInput.focus();
        return;
      }
      var curOption, curOptionIndex;
      (activeArrOptions.length <= 0 ? _resetOptions() : _setCurrentOption() );
      switch(e.keyCode){
        case 27: case 9: // "Tab key" & "Esc key"
          setSelectState(false);
          selectDummyInput.focus();
        break;
        case 8: // "Backspace key"
          _resetOptions();
          _keyDefaultHandler();
        break;
        case 32: // "Space key"
          (selectContent.getAttribute(sShowAtt) !== "true" ? selectDummyInput.click() : null);
        break;
        case 13: // "Enter key"
          if(selectDummyInput.getAttribute('aria-expanded') !== "false" ){
            curOption.click();
            selectDummyInput.focus();
          }
          if(selectContentInput !== null){
            _keyDefaultHandler(selectContentList.querySelector('['+ sSelectedAtt +'="true"]'));
          };
        break;
        case 38: // "Arrow up key"
            (activeArrOptions.length > 0 ? _keyUpHandler() : null );
        break;
        case 40: // "Arrow down key"
            (activeArrOptions.length > 0 ? _keyDownHandler() : null);
        break;
        default:
          (selectDummyInput.getAttribute('aria-expanded') !== "false" ? _keyDefaultHandler() : null);
        break;
      }

      if(activeArrOptions.length > 0){
        _setCurrentOption();
      }

      function _keyUpHandler(){
        e.preventDefault();

        if(selectContent.getAttribute(sShowAtt) !== "true"){
          // Tonen bij arrow down en index één verhogen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex++;
        }

        if(curOptionIndex > 0){
          curOptionIndex--;
          curOption.removeAttribute(sSelectedAtt);
          var el = selectContentList.querySelector('['+ sRecordAtt +']['+sIndexAtt+'="'+curOptionIndex+'"]');
          el.setAttribute(sSelectedAtt,'true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function _keyDownHandler(){
        e.preventDefault();

        if(selectContent.getAttribute(sShowAtt) !== "true"){
          // Tonen bij arrow down en index één minderen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex--;
        }

        if(curOptionIndex < (activeArrOptions.length - 1)){
          curOptionIndex++;
          curOption.removeAttribute(sSelectedAtt);
          var el = selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="'+curOptionIndex+'"]');
          el.setAttribute(sSelectedAtt,'true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function _keyDefaultHandler(selectedEl){
        selectedEl = selectedEl || null;
        if(selectContentInput !== null){
          var val = selectContentInput.value, first; activeArrOptions = []; // Clear activeArrOptions
          if(params !== undefined && params.callbackFn !== null && params.callbackFn !== undefined){ // If dynamic load is enabled
            var obj = {
              searchStr: val,
              callbackFn: cb
            }
            var jsonData = params.callbackFn(selectField, obj);
            function cb(jsonData){
              if(jsonData !== null && typeof jsonData === 'object'){
                _repopuplateSelect(jsonData);
              }else if(jsonData === false){
                _clearSelectOptions();
                setNoResultsFoundElement("show", selectField, selectContentList, params);
              }
            }
          }else{
            for(var item, i = 0; item = arrOptions[i]; i++) { // If dynamic load isn't enabled
              var el = selectContentList.querySelector('['+ sRecordAtt +']['+sLabelAtt+'="'+item+'"]');
              // Set visibility hidden of all items & Remove old index of all items & Remove old focus
              el.setAttribute(sShowAtt, 'false');
              el.removeAttribute(sIndexAtt);
              el.removeAttribute(sSelectedAtt);

              // If substring is present in string show item and push to array
              if(item.toLowerCase().indexOf(val.toLowerCase() ) > -1){
                el.setAttribute(sShowAtt, 'true');
                activeArrOptions.push(item);
              }
            }

            if(activeArrOptions.length > 0){ // Wel resultaten
              setNoResultsFoundElement("hide", selectField, selectContentList, params);
              for(var opt, i = 0; opt = activeArrOptions[i]; i++) {
                selectContentList.querySelector('['+ sRecordAtt +']['+sLabelAtt+'="'+opt+'"]').setAttribute(sIndexAtt, i);
              }
              // Set focus on first element
              if(selectedEl !== null) {
                selectedEl.setAttribute(sSelectedAtt,'true');
                window.setTimeout(function(){
                  selectedEl.focus();
                  selectContentInput.focus();
                }, 1);
              } else {
                var el = selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]');
                (el !== null ? el.setAttribute(sSelectedAtt,'true') : null );
              }
            }else{ // Wel resultaten
              setNoResultsFoundElement("show", selectField, selectContentList, params);
            }

            var optgroups = selectContentList.getElementsByClassName(sGroupClass);
            [].forEach.call(optgroups, function(optgroup){
              var items = optgroup.querySelectorAll('.' + sCtaClass + '['+sShowAtt+'="true"]');
              (!items.length ? optgroup.style.display = "none" : optgroup.style.display = "block" );
            });
          }
        }
      }

      function _setCurrentOption(){
        curOption = selectContentList.querySelector('['+sRecordAtt+']['+sSelectedAtt+'="true"]');

        (curOption == null ? curOption = selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]') : null);
        curOptionIndex = curOption.getAttribute(sIndexAtt);
      }

      function _resetOptions(){
        for(var item, i = 0; item = arrOptions[i]; i++) {
          var el = selectContentList.querySelector('['+ sRecordAtt +']['+sLabelAtt+'="'+item+'"]');
              el.removeAttribute(sSelectedAtt);
              el.setAttribute(sShowAtt, 'true');
              el.setAttribute(sIndexAtt, i);
        }

        if(selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]') !== null)
          selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]').setAttribute(sSelectedAtt,'true');

        activeArrOptions = arrOptions;
      }

      function _repopuplateSelect(jsonData){

        _clearSelectOptions(selectField);

        // Loop through the jsonData string
        [].forEach.call(jsonData, function(el){
          switch(el.type){
            case "option":
              var option = document.createElement("option");

              option.text = el.label;
              option.setAttribute('value', el.value);
              selectField.add(option);

              while (selectList.hasChildNodes())
                selectList.removeChild(selectList.lastChild);

                    // Generate list items based on options in real select
                    var i = 0;
                    [].forEach.call(selectField.options, function(opt){
                      var value = opt.value;
                      var label = opt.innerHTML;

                      // If item has "data-placeholder" it's used as a placeholder item
                      if(opt.hasAttribute(sPlaceholderAtt)){
                        selectDummyInput.innerHTML = label;
                      }else{
                        // SelectOption
                        var selectOption = document.createElement('div');
                        addClass(selectOption, sItemClass);

                        // Titel (button wrapper)
                        var selectOptionButton = document.createElement('button');
                            addClass(selectOptionButton, sCtaClass);

                            var closestOptGroup = opt.closest('optgroup');

                            selectOptionButton.setAttribute('type', 'button');
                            selectOptionButton.setAttribute(sIndexAtt, i);
                            selectOptionButton.setAttribute(sValueAtt, value);
                            selectOptionButton.setAttribute(sLabelAtt, label);
                            selectOptionButton.setAttribute(sRecordAtt,'');
                            selectOptionButton.setAttribute(sFocusAtt, '');
                            selectOptionButton.setAttribute('role', 'option');
                            selectOptionButton.setAttribute('tabindex','-1');

                            // selected state for first elem
                            if(i == 0){
                              selectOptionButton.setAttribute(sSelectedAtt, 'true');
                              addClass(selectOptionButton, sCtaActiveClass);
                            }

                            // Titel (span wrapper)
                            var selectOptionTitleSpan = document.createElement("span");
                                addClass(selectOptionTitleSpan, sCtaTitleClass);
                                selectOptionTitleSpan.innerHTML = label;

                                // Appends
                                selectOptionButton.appendChild(selectOptionTitleSpan);
                            selectOption.appendChild(selectOptionButton);

                        selectList.appendChild(selectOption);

                        // Add to arrOptions array
                        arrOptions.push(label);
                        i++;
                      }
                    });
            break;

            // case "optiongroup":

            // break;
          }

          activeArrOptions = arrOptions;
          setRecordEvents();
        });
      }

      function _clearSelectOptions(){
        // Clear arrOptions
        arrOptions = [];

        var c;
        for(c = selectField.options.length - 1 ; c >= 0 ; c--){
            selectField.remove(c);
        }

        // Remove nodes in selectList
        while (selectList.hasChildNodes())
          selectList.removeChild(selectList.lastChild);
      }
    };

    /*
    * Eventhandler | selectContainer Blur
    */
    function selectContentInputBlurHandler(e){
      window.setTimeout(function(){
        if(selectForm !== null){
          validate = new fiveForms({id: selectForm.getAttribute('id')});
          var obj = { "obj": selectField, "name": selectField.name, "type": selectField.nodeName.toLowerCase() };
          var vf = validate.validateField(obj);

          if(hasClass(document.activeElement,sCtaClass)){
            removeClass(selectDummyInput, 'error');
            removeClass(selectField, selectField.getAttribute(sErrClassAtt));
            if (selectField.hasAttribute(sErrMsgAtt) && selectField.hasAttribute(sErrPlaceholderAtt) && document.querySelector('['+sErrIDAtt+'=' + selectField.getAttribute(sErrPlaceholderAtt) + ']')) {
              (document.querySelector('['+sErrIDAtt+'=' + selectField.getAttribute(sErrPlaceholderAtt) + ']')).innerHTML = '';
            }
            // add error data-attribute
            selectField.setAttribute(sHasErrAtt, false);
            return;
          }

          if(vf.error){
            _addError();
          }else{
            _removeError();
          }
        }


        function _addError(){
          addClass(selectDummyInput, 'error');
            addClass(vf.obj, vf.obj.getAttribute(sErrClassAtt));
            // show form error if it is defined
            if (vf.obj.hasAttribute(sErrMsgAtt) && vf.obj.hasAttribute(sErrPlaceholderAtt) && document.querySelector('['+sErrIDAtt+'=' + vf.obj.getAttribute(sErrPlaceholderAtt) + ']')) {
              (document.querySelector('['+sErrIDAtt+'=' + vf.obj.getAttribute(sErrPlaceholderAtt) + ']')).innerHTML = vf.obj.getAttribute(sErrMsgAtt);
            }
            // add error data-attribute
            vf.obj.setAttribute(sHasErrAtt, true);
        }

        function _removeError(){
          removeClass(selectDummyInput, 'error');
            removeClass(vf.obj, vf.obj.getAttribute(sErrClassAtt));
            if (vf.obj.hasAttribute(sErrMsgAtt) && vf.obj.hasAttribute(sErrPlaceholderAtt) && document.querySelector('['+sErrIDAtt+'=' + vf.obj.getAttribute(sErrPlaceholderAtt) + ']')) {
              (document.querySelector('['+sErrIDAtt+'=' + vf.obj.getAttribute(sErrPlaceholderAtt) + ']')).innerHTML = '';
            }
            // add error data-attribute
            vf.obj.setAttribute(sHasErrAtt, false);
        }
      }, 10);
    };


    /*
    * Eventhandler | selectContainer keyDown
    */
    function selectContainerKeyDownEventHandler(e){
      switch(e.keyCode){
        case 13: case 38: case 40:
        e.preventDefault();
        break;
      }
    };

    /*
    * Eventhandler | selectDummyInput Click
    */
    function selectDummyInputClickEventHandler(e){
      if(selectContent.getAttribute(sShowAtt) === "false"){
        // Show select
        setSelectState(true);
        // Set focus on search if present
        selectContentInput.focus();
        // Set selected option or first option active
        if(originalSelectOption !== null){
          selectContentList.querySelector('['+sRecordAtt+']['+sLabelAtt+'="'+originalSelectOption+'"]').setAttribute(sSelectedAtt, 'true');
        }else{
          if(selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]') !== null)
            selectContentList.querySelector('['+sRecordAtt+']['+sIndexAtt+'="0"]').setAttribute(sSelectedAtt,'true');
        }
      }
      else{
        setSelectState(false);
        selectDummyInput.focus();
      }
    };

    /*
    * Loop through dynamically generated records
    */
    setRecordEvents();
    function setRecordEvents(){
      var selectContentListItems = selectContent.querySelectorAll('['+sRecordAtt+']');

      [].forEach.call(selectContentListItems, function(item){
        item.addEventListener('click', function(e){
          var lbl = item.getAttribute(sLabelAtt);
          var val = item.getAttribute(sValueAtt);

          // Set selected state to original select
          originalSelectOption = setOriginalSelectFieldOption(selectId, val);

          // Set label in dummy input
          selectDummyInput.innerHTML = lbl;

          // Close select
          setSelectState(false);
          selectDummyInput.focus();

          // Remove active class of alle elements
          [].forEach.call(selectContentListItems, function(item2){
            removeClass(item2, sCtaActiveClass);
            item2.removeAttribute(sSelectedAtt);
          });

          // Add active class to selected element
          addClass(item, sCtaActiveClass);

          // Remove query in select input
          if(selectContentInput !== null)
            selectContentInput.value = "";
        });
      });
    };

    /*
    * setSelectState()
    * Setting the general data attributes & aria tags of the generated select
    */
    function setSelectState(isShown){
      if(isShown) {
        var dataShow = true,
            ariaHidden = false,
            ariaExpanded = true;
      } else {
        var dataShow = false,
            ariaHidden = true,
            ariaExpanded = false;
      };

      selectContent.setAttribute(sShowAtt, dataShow);
      selectContent.setAttribute('aria-hidden', ariaHidden);
      selectDummyInput.setAttribute('aria-expanded', ariaExpanded);
    };
  };

  /*
  * Loop through all select fields
  */
  vl.select.dressAll = function(){

    var selectFields = document.querySelectorAll('['+sAtt+']:not(.' + sBoundClass + ')');

    [].forEach.call(selectFields, function(selectField) {
      vl.select.dress(selectField);
    });
  };

  // Initiate
  vl.select.dressAll();

  /*
  * setDisabledState()
  * Sets disabled state of both native and generated select
  * @param selectField(object)
  * @param state(boolean)
  */
  vl.select.setDisabledState = function(selectField, state) {
    var selectContainer   = selectField.closest('.' + sClass);
    var selectDummyInput  = selectContainer.getElementsByClassName(sInputClass)[0];

    if(state){
      selectField.setAttribute('disabled', state);
      selectDummyInput.setAttribute('disabled', state);
    }else{
      selectField.removeAttribute('disabled');
      selectDummyInput.removeAttribute('disabled');
    }
  };

  /*
  * setVisibilityAttributes()
  * Setting the general data attributes & aria tags
  */
  function setVisibilityAttributes(field, dataShow, ariaHidden){
      field.setAttribute(sShowAtt,   dataShow);
      field.setAttribute('aria-hidden', ariaHidden);
  };

  /*
  * generateSelect()
  * Generating the ehanced select
  */
  function generateSelect(selectField){
    // Hide normal select field
    addClass(selectField, vl.ns + 'u-visually-hidden');
    selectField.setAttribute('aria-hidden','true');
    selectField.setAttribute('tabindex','-1');

    // Variables
    var arr = [], uniqId = uniqueId();

    // Set selectContainer
    var selectContainer = selectField.closest('.' + sClass);

    // Get data-id or generate one
    if(selectField.hasAttribute(sIDAtt)){
      uniqId = selectField.getAttribute(sIDAtt);
      selectContainer.setAttribute(sIDAtt, uniqId);
    }else{
      selectContainer.setAttribute(sIDAtt, uniqId);
    }

    // Fake select field
    var selectDummyInput = document.createElement("button");
        selectDummyInput.setAttribute('type','button');
        selectDummyInput.setAttribute(sFocusAtt, '');
        selectDummyInput.setAttribute('id', uniqId);
        selectDummyInput.setAttribute('aria-haspopup', 'true');
        selectDummyInput.setAttribute('aria-expanded', 'false');
        addClass(selectDummyInput, sInputClass);
        if(selectField.hasAttribute('disabled') && selectField.getAttribute('disabled') !== "false")
          selectDummyInput.setAttribute('disabled','true');

        selectContainer.insertBefore(selectDummyInput, selectContainer.firstChild);


    // Select Wrapper
    var selectWrapper = document.createElement("div");
        addClass(selectWrapper, sWrapperClass);
        selectWrapper.setAttribute(sContentAtt,'');
        selectWrapper.setAttribute('aria-labelledby',uniqId);
        setVisibilityAttributes(selectWrapper, false, true);

        selectContainer.appendChild(selectWrapper);

        // Select Form Wrapper

        var selectForm = document.createElement("div");
            addClass(selectForm, sFormClass);

            selectWrapper.appendChild(selectForm);

            // Select Form Input
            var selectFormInput = document.createElement('input');
                selectFormInput.setAttribute('type','text');
                selectFormInput.setAttribute('autocomplete','off');
                addClass(selectFormInput, vl.ns + 'input-field');
                addClass(selectFormInput, vl.ns + 'input-field--block');
                selectFormInput.setAttribute(sInputAtt,'');
                selectFormInput.setAttribute(sFocusAtt, '');
                selectFormInput.setAttribute('value','');
                selectFormInput.setAttribute('tabindex','-1');
                selectFormInput.setAttribute('aria-describedby', vl.ns + 'selectFormInputDescription' + uniqId);
                selectFormInput.setAttribute('aria-haspopup', 'listbox"');

                selectForm.appendChild(selectFormInput);

            var selectFormInputDescription = document.createElement('span');
                selectFormInputDescription.setAttribute('id',vl.ns + 'selectFormInputDescription' + uniqId);
                selectFormInputDescription.innerHTML = "U bevindt zich in de zoekfunctie van een dropdown menu in een formulier. Navigeer door de opties met ctrl + alt + pijltjes en selecteer met enter. Gebruik escape om de dropdown te sluiten.";
                addClass(selectFormInputDescription, vl.ns + 'u-visually-hidden');

                selectForm.appendChild(selectFormInputDescription);


        // Select List Wrapper
        var selectListWappper = document.createElement('div');
                addClass(selectListWappper, sListWrapperClass);
                selectListWappper.setAttribute('role','listbox');

                selectWrapper.appendChild(selectListWappper);

                // Select List
                var selectList = document.createElement('section');
                    addClass(selectList, sListClass);
                    selectList.setAttribute(sRecordsAtt,'');

                    selectListWappper.appendChild(selectList);

                    // Generate option groups based on optgroups in real select
                    var optgroups = selectField.querySelectorAll('optgroup');
                    if(optgroups.length > 0){
                      [].forEach.call(optgroups, function(optgroup){
                        var label = optgroup.getAttribute('label');
                        var selectOptionGroupWrapper = document.createElement('section');
                        addClass(selectOptionGroupWrapper, sGroupClass);
                        selectOptionGroupWrapper.setAttribute(sLabelAtt, label);
                        selectOptionGroupWrapper.setAttribute('role', 'group');
                        selectList.appendChild(selectOptionGroupWrapper);

                        var selectOptionGroupTitle = document.createElement('h1');
                        selectOptionGroupTitle.innerHTML = label;

                        selectOptionGroupWrapper.appendChild(selectOptionGroupTitle);
                      });
                    }

                    // Generate list items based on options in real select
                    var i = 0;
                    [].forEach.call(selectField.options, function(opt){
                      var value = opt.value;
                      var label = opt.innerHTML;

                      // If item has "data-placeholder" it's used as a placeholder item
                      if(opt.hasAttribute(sPlaceholderAtt)){
                        selectDummyInput.innerHTML = label;
                      }else{
                        // SelectOption
                        var selectOption = document.createElement('div');
                        addClass(selectOption, sItemClass);

                        // Titel (button wrapper)
                        var selectOptionButton = document.createElement('button');
                            addClass(selectOptionButton, sCtaClass);
                            // If option is selected set the element active and change the label in the DummyInput
                            if(opt.selected){
                              addClass(selectOptionButton, sCtaActiveClass);
                              selectDummyInput.innerHTML = label;
                              selectOptionButton.setAttribute('aria-selected', true);
                            }else{
                              selectOptionButton.setAttribute('aria-selected', false);
                            }

                            var closestOptGroup = opt.closest('optgroup');

                            selectOptionButton.setAttribute('type', 'button');
                            selectOptionButton.setAttribute(sIndexAtt, i);
                            selectOptionButton.setAttribute(sValueAtt, value);
                            selectOptionButton.setAttribute(sLabelAtt, label);
                            selectOptionButton.setAttribute(sRecordAtt,'');
                            selectOptionButton.setAttribute(sFocusAtt, '');
                            selectOptionButton.setAttribute('role', 'option');
                            selectOptionButton.setAttribute('tabindex','-1');

                            // Titel (span wrapper)
                            var selectOptionTitleSpan = document.createElement("span");
                                addClass(selectOptionTitleSpan, sCtaTitleClass);
                                selectOptionTitleSpan.innerHTML = label;

                                // Appends
                                selectOptionButton.appendChild(selectOptionTitleSpan);
                            selectOption.appendChild(selectOptionButton);

                        // Assign to option group if available
                        if(closestOptGroup !== null){
                          var closestGeneratedOptGroup = selectList.querySelector('['+sLabelAtt+'="' + closestOptGroup.getAttribute('label') + '"]')
                          closestGeneratedOptGroup.appendChild(selectOption);
                        }else{
                          selectList.appendChild(selectOption);
                        }

                        // Add to arrOptions array
                        arr.push(label);
                        i++;
                      }
                    });

      return [arr, uniqId, selectContainer, selectList];
  };

  /*
  * setNoResultsFoundElement()
  * Generate the "no results found" option
  */
  function setNoResultsFoundElement(state, selectField, selectContentList, params){

    switch(state){
      case "show":
        var prevEl = selectContentList.querySelector('['+sEmptyAtt+']');

        if(prevEl == null){
          var noResultsFoundElement = document.createElement('div');
              addClass(noResultsFoundElement, sItemClass);

              selectContentList.appendChild(noResultsFoundElement);

              var noResultsFoundElementContent = document.createElement('div');
                  addClass(noResultsFoundElementContent, sEmptyClass);
                  noResultsFoundElementContent.setAttribute(sEmptyAtt, '');

                  if(params !== undefined && params.noResultsFound !== undefined && params.noResultsFound.title !== undefined){
                    noResultsFoundElementContent.innerHTML = params.noResultsFound.title;
                  }else{
                    noResultsFoundElementContent.innerHTML = dfNoResultsFound;
                  }

                  noResultsFoundElement.appendChild(noResultsFoundElementContent);
        }
      break;

      case "hide":
        var prevEl = selectContentList.querySelector('['+sEmptyAtt+']');
        if(prevEl !== null){
          removeElement(prevEl);
        }
      break;
    }
  };

  /*
  * setOriginalSelectFieldOption()
  * Setting the option in the hidden select field equal to the element selected in the generated select
  */
  function setOriginalSelectFieldOption(selectId, val){
    var sel = document.querySelector('.' + sClass + '['+sIDAtt+'="'+selectId+'"] select');
    for(var opt, j = 0; opt = sel.options[j]; j++) {
      if(opt.value == val) {
          sel.selectedIndex = j;
          fireEvent(sel, 'vl.select.hasChanged');
          return opt.label;
          break;
      }
    }
  };

})();

