vl.textclamp = {};
vl.textclamp.dress;
vl.textclamp.dressAll;

(function() {
  var tcClampedClass = 'js-' + vl.ns + 'clamped',
      tcNoTriggerClass = 'js-' + vl.ns + 'clamp-useless',
      tcBoundClass = 'js-' + vl.ns + 'clamp-bound',
      tcAtt = 'data-' + vl.ns + 'clamp',
      tcIdAtt = 'data-' + vl.ns + 'clamp-id',
      tcTargetAtt = 'data-' + vl.ns + 'clamp-target';

  /**
   * Clamp text to the given number of lines
   */
  vl.textclamp.dressAll = function () {
    // get all elements with a data-attribute data-clamp
    var elements = document.querySelectorAll('['+ tcAtt +']');
    // apply clamping for all attributes
    [].forEach.call(elements, function(element) {
      vl.textclamp.dress(element);
    });
  }

  vl.textclamp.dress = function(element) {
    var clampSize = parseInt(element.getAttribute(tcAtt));
    if(clampSize <= 0){
      return;
    }
    // do the actual clamping
    clamp(element, clampSize);
    // check if there is actually content clamped, we need this to decide if the read-more button is usefull or not.
    if(element.hasAttribute(tcIdAtt)){
      // get the trigger
      var trigger = document.querySelector('['+ tcTargetAtt +'="' + element.getAttribute(tcIdAtt) + '"]');
      // check if the trigger exists and has not already been bound
      if(trigger !== null && !hasClass(trigger, tcBoundClass)){
        addClass(trigger, tcBoundClass);
        // attach the event listener
        trigger.addEventListener('click', function(e){
          e.preventDefault();
          undoOverflowClamp(element, this);
        });
      }
      // show / hide the button if it is required
      if(hasClass(element, tcClampedClass)){
        // the item is clamped so it can be expanded
        removeClass(trigger, tcNoTriggerClass);
      }else{
        addClass(trigger, tcNoTriggerClass);
      }
    }
  }

  // initialize on pageLoad
  window.addEventListener('load', function() {
    // Call text clamping
    vl.textclamp.dressAll();
    // bind on resize event
    window.addEventListener('resize', vl.textclamp.dressAll);
  });

  /**
   * Dress all "read more" buttons to be able to undo clamping
   */
  function undoOverflowClamp(element, trigger){
    // set raw text in the element
    var text = (element.textContent || element.innerText);
    element.textContent = text;
    // remove data-clamp attribute
    element.removeAttribute(tcAtt);
    element.removeAttribute(tcIdAtt);
    // remove clamped class
    removeClass(element, tcClampedClass);
    // delete the button, it has become completely useless
    trigger.parentNode.removeChild(trigger);
  }
})();
