/**
 * Drilldown navigation
**/

vl.drilldown = {};
vl.drilldown.dress;
vl.drilldown.dressAll;

(function () {

  var column                      = vl.ns + 'drilldown__column',
      subcolumn                   = vl.ns + 'drilldown__subcolumn',
      subcolumnHeader             = vl.ns + 'drilldown__subcolumn__header',
      activeSubcolumn             = vl.ns + 'drilldown__subcolumn--active',
      listWrapper                 = vl.ns + 'drilldown__list-wrapper',
      item                        = vl.ns + 'drilldown__item',
      callToAction                = vl.ns + 'drilldown__item__cta',
      activeCallToAction          = vl.ns + 'drilldown__item__cta--active',
      backCallToAction            = vl.ns + 'drilldown__item__cta--back',
      ddBoundClass                = 'js-' + vl.ns + 'drilldown__item__cta--active',
      ddEmptyClass                = vl.ns + 'drilldown__empty',
      ddCtaCoverClass             = vl.ns + 'drilldown__cta-cover',
      ddListClass                 = vl.ns + 'drilldown__list',
      ddSubcolumnSearchClass      = vl.ns + 'drilldown__subcolumn__search',
      ddSubcolumnSearchInputClass = vl.ns + 'drilldown__subcolumn__search__input',
      ddInputFieldClass           = vl.ns + 'input-field',
      ddInputFieldBlockClass      = vl.ns + 'input-field--block',
      dataPrefix                  = 'data-' + vl.ns,
      ddAtt                       = dataPrefix + 'drilldown',
      ddCtaAtt                    = dataPrefix + 'drilldown-cta',
      ddBackAtt                   = dataPrefix + 'drilldown-back',
      ddCoverAtt                  = dataPrefix + 'drilldown-cover',
      ddColumnAtt                 = dataPrefix + 'drilldown-column',
      ddColumnsAtt                = dataPrefix + 'drilldown-columns',
      ddDepthAtt                  = dataPrefix + 'drilldown-depth',
      ddIndexDepthAtt             = dataPrefix + 'drilldown-index-depth',
      ddIndexAtt                  = dataPrefix + 'index',
      ddFocusAtt                  = dataPrefix + 'focus',
      ddInputAtt                  = dataPrefix + 'input',
      ddSearchAtt                 = dataPrefix + 'search',
      ddSearchEmptyAtt            = dataPrefix + 'search-empty',
      ddSearchPhAtt               = dataPrefix + 'search-ph';

  vl.drilldown.dress = function(drilldown) {
    addClass(drilldown, ddBoundClass);

    // Set search
    var hasSearch = false;
    if(drilldown.getAttribute(ddSearchAtt) !== "false"){
      hasSearch = true;
    }

    // Generate all extra attributes
    generateDrilldownAttributes(drilldown, hasSearch);

    // Set variables
    var callToActions       = drilldown.querySelectorAll('['+ddCtaAtt+']');
    var backCallToActions   = drilldown.querySelectorAll('['+ddBackAtt+']');
    var coverCallToActions  = drilldown.querySelectorAll('['+ddCoverAtt+']');
    var inputFields         = drilldown.querySelectorAll('['+ddInputAtt+']');
    var columns             = parseInt(drilldown.getAttribute(ddColumnsAtt));

    /*
    * loop General CTA's
    */
    [].forEach.call(callToActions, function (cta) {
      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get state of clicked element
        var isActive = hasClass(cta, activeCallToAction);

        // Get subcolumn of clicked element
        var parentItem = cta.closest('.' + item);
        var child = parentItem.querySelector('.' + subcolumn);

        // Get parent column of clicked element
        var parentColumn = cta.closest('['+ddColumnAtt+']');
        removeActiveCtas(parentColumn);
        removeActiveColumns(parentColumn);

        // Toggle the active class based on its active state
        if(!isActive) {
          dressSubColumn(this, child);
          addClass(cta, activeCallToAction);
        }else{
          killSubColumn(this, child);
          removeClass(cta, activeCallToAction);
        }
        triggerResize();

      });
    });

    /*
    * loop Back Buttons
    */
    [].forEach.call(backCallToActions, function (cta) {
      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get subcolumn
        var col = cta.closest('.' + subcolumn);
        killSubColumn(this, col);

        // Get parent's active CTA and remove the active state
        var parent = col.closest('.' + item);
        var activeCta = parent.querySelector('.' + callToAction);
        removeClass(activeCta, activeCallToAction);

        triggerResize();

        return false;
      });
    });

    /*
    * loop Cover CTA's
    */
    [].forEach.call(coverCallToActions, function (cta) {

      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get subcolumn
        var depth = parseInt(cta.getAttribute(ddCoverAtt));
        var col = document.querySelector('['+ddDepthAtt+'="' + (depth +1) + '"]'); // Plus 1 to kill the next column, not the current one

        killSubColumn(this, col);

        // Get parent's active CTA and remove the active state
        var parent = col.closest('.' + item);
        var activeCta = parent.querySelector('.' + callToAction);
        removeClass(activeCta, activeCallToAction);
        triggerResize();
      });
    });

    /*
    * loop data inputs and add search functionality
    */
    [].forEach.call(inputFields, function(inputField){

      var subcol = inputField.closest('['+ddColumnAtt+']');
      var depth = subcol.getAttribute(ddDepthAtt);
      var items = subcol.querySelectorAll('['+ddIndexDepthAtt+'="'+depth+'"]');

      inputField.addEventListener('keyup', function(e){

        if(e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 27 && e.keyCode !== 30 && e.keyCode !== 13){
          var searchString = inputField.value.toLowerCase();
          var k = 0, options = [];
          [].forEach.call(items, function(item){
            var term = stripTags(item.innerHTML.toLowerCase());

            item.removeAttribute(ddIndexAtt);
            item.removeAttribute(ddFocusAtt);
            item.setAttribute('aria-hidden', 'true');

            if(term.indexOf(searchString ) > -1){
              item.setAttribute(ddIndexAtt, k);
              if(k == 0){
                item.setAttribute(ddFocusAtt, 'true');
              }

              options.push(item);
              item.setAttribute('aria-hidden', 'false');
              k++;
            }
          });

          // No results found
          if(options.length <= 0){
            var el = drilldown.querySelector('.' + ddEmptyClass);
            if(el == null){
              var noResultsFoundEl = document.createElement("div");
                  addClass(noResultsFoundEl, ddEmptyClass);

                  var msg = drilldown.getAttribute(ddSearchEmptyAtt);
                  if(msg == null){
                    msg = "Geen resultaten gevonden";
                  }
                  noResultsFoundEl.innerHTML = msg;

              var wrapper = subcol.querySelector('.' + listWrapper);
              wrapper.appendChild(noResultsFoundEl);
            }
          }else{
            var el = drilldown.querySelector('.' + ddEmptyClass);
            if(el !== null){
              removeElement(el);
            }
          }
        }
      });
    });

    /*
    * dressSubColumn()
    * - Initiates a subcolumn
    * - Sets current columns
    */
    var dressSubColumn = function(cta, subcol){
      // Increase count of columns
      var curCols = parseInt(subcol.getAttribute(ddDepthAtt));
      drilldown.setAttribute(ddColumnsAtt, curCols);

      // Add active state of subcolumn
      addClass(subcol, activeSubcolumn);
      subcol.setAttribute('aria-expanded', true);

      // Set focus on first element and (optional) search
      setFocus(subcol);
    }

    /*
    * killSubColumn()
    * Hides a subcolumn
    * - Sets current columns
    */
    var killSubColumn = function(cta, subcol){
      // Decrease count of columns
      var curCols = parseInt(subcol.getAttribute(ddDepthAtt)) - 1;
      drilldown.setAttribute(ddColumnsAtt, curCols);
      // Remove active state of subcolumn
      removeClass(subcol, activeSubcolumn);
      subcol.setAttribute('aria-expanded', false);

      var activeEls = subcol.querySelectorAll('['+ddFocusAtt+'="true"]');
      [].forEach.call(activeEls, function(el){
        el.removeAttribute(ddFocusAtt);
      });

      // Set focus on new active col
      var activeCol = subcol.closest('['+ddColumnAtt+']['+ddDepthAtt+'="'+curCols+'"]');
      setFocus(activeCol);
    }

    /*
    * Drilldown keydown events
    * Progressive enhancement for Drilldown
    * - Arrow keys (up/down) = go up/down the ladder
    * - Escape key = Close current column and go back one step
    * - Enter + Spacebar = Click event on element
    */
    drilldown.addEventListener('keydown', function(e){

      switch(e.keyCode){
        // Escape up
        case 27:
          e.preventDefault();
          var activeColIndex = drilldown.getAttribute(ddColumnsAtt);
          var activeItem = drilldown.querySelector('['+ddDepthAtt+'="' + activeColIndex + '"] ['+ddFocusAtt+'="true"]');
          if(activeItem !== null){
            var col = activeItem.closest('.' + subcolumn);
            if(col !== null){
              var closeButton = col.querySelector('['+ddBackAtt+']');
              closeButton.click();
            }
          }
        break;

        // Arrow up
        case 38:
          e.preventDefault();
          moveFocus("up");
        break;

        // Arrow down
        case 40:
          e.preventDefault();
          moveFocus("down");
        break;

        // Enter / Spacebar
        case 13:
          var activeColIndex = drilldown.getAttribute(ddColumnsAtt);
          var activeItem = drilldown.querySelector('['+ddDepthAtt+'="' + activeColIndex + '"] ['+ddFocusAtt+'="true"]');
          if(activeItem !== null){
            e.preventDefault();
            activeItem.click();
          }
        break;
      }
    });

    var setFocus = function(col){
      // Set timeout necessary to wait for end of animation
      window.setTimeout(function(){
        var activeEl = col.querySelector('['+ddFocusAtt+'="true"]');
        if(activeEl == null){
          col.querySelector('['+ddIndexAtt+'="0"]').focus();
          col.querySelector('['+ddIndexAtt+'="0"]').setAttribute(ddFocusAtt, 'true');
        }else{
          activeEl.focus();
        }

        if(hasClass(col, subcolumn) && hasSearch){
          col.querySelector('['+ddInputAtt+']').focus();
        }
      }, 200);
    }

    var moveFocus = function(direction){
      var activeColIndex = drilldown.getAttribute(ddColumnsAtt);

      var activeItem = drilldown.querySelector('['+ddDepthAtt+'="' + activeColIndex + '"] ['+ddFocusAtt+'="true"]');
      if(activeItem !== null){

        var curIndex = activeItem.getAttribute(ddIndexAtt);
        var colItems = drilldown.querySelectorAll('['+ddIndexDepthAtt+'="'+ activeColIndex +'"][aria-hidden="false"]');

        switch(direction){
          case "up":
            if(curIndex > 0){
              curIndex --;
              _setActiveStateAndFocus();
            }
          break;

          case "down":
          //  Checken als cuindex kleiner is dan aantal items in de kolom
            if(curIndex < (colItems.length - 1)){
              curIndex ++;
              _setActiveStateAndFocus();
            }
          break;
        }
      }


      function _setActiveStateAndFocus(){
        activeItem.removeAttribute(ddFocusAtt);
        var newItem = drilldown.querySelector('['+ddIndexDepthAtt+'="' + activeColIndex + '"]['+ddIndexAtt+'="' + curIndex + '"]');
        newItem.focus();
        if(hasSearch){
          var searchInput = drilldown.querySelector('['+ddDepthAtt+'="' + activeColIndex + '"] ['+ddInputAtt+']');
          searchInput.focus();
        }
        newItem.setAttribute(ddFocusAtt,'true');
      }
    }

    /*
    * removeActiveCtas()
    * Removes all active CTA classes from CTA's starting from parentNode downwards
    */
    var removeActiveCtas = function(parentColumn){
      var activeCtas = parentColumn.querySelectorAll('.' + activeCallToAction);
      if(activeCtas !== null){
        [].forEach.call(activeCtas, function(e){
          removeClass(e, activeCallToAction);
        });
      }
    }

    /*
    * removeActiveColumns()
    * Removes all active Column classes from columns starting from parentNode downwards
    */
    var removeActiveColumns = function(parentColumn){
      var els = parentColumn.querySelectorAll('['+ddColumnAtt+']');

      if(els !== null){
        [].forEach.call(els, function(e){
          if(hasClass(e, activeSubcolumn)){
            removeClass(e, activeSubcolumn);
          }
        });
      }
    }

    /*
    * triggerResize()
    * Invokes a resize event so equalheight is recalculated
    */
    var triggerResize = function(){
        var cols = drilldown.querySelectorAll('.' + subcolumn);
        var maincol = drilldown.querySelector('.' + column);
        var maxHeight = "";
        maincol.style.height = "";

        // Get max height
        [].forEach.call(cols, function(col){
          // Variables
          var wrapper = col.querySelector('.' + listWrapper);
          var header = col.querySelector('.' + subcolumnHeader);
          // Reset all
          wrapper.style.height = "";
          col.style.height = "";
          // Get height
          var colHeight = col.offsetHeight;
          // Check if height exceeds windowheight
          if(colHeight > maxHeight){
            if(colHeight > (window.innerHeight/1.5)){
              maxHeight = window.innerHeight/1.5;
            }else{
              maxHeight = colHeight;
            }
          }
          // Apply height to wrapper
          wrapper.style.height = (maxHeight - header.offsetHeight) + "px";
        });

        // Apply max height on all subcols
        [].forEach.call(cols, function(col){
          col.style.height = maxHeight + "px";
        });
        // Apply max height on first col
        maincol.style.height = maxHeight + "px";
    }

    /*
    * generateDrilldownAttributes();
    * Generate extra attributes through JS
    */
    function generateDrilldownAttributes(drilldown, hasSearch){

      // Drilldown
      drilldown.setAttribute(ddColumnsAtt, '1');

      // Drilldown cols
      var cols = drilldown.querySelectorAll('.' + column + ', .' + subcolumn);

      var first = false;
      var k = 0;
      [].forEach.call(cols, function(col){
        col.setAttribute(ddColumnAtt,'');
        var depth = col.getAttribute(ddDepthAtt);

        // Generate cover (go back) cta
        _generateCoverCta(col, depth);

        // Generate back cta (arrow)
        _generateBackCta(col);

        // Generate search
        if(hasClass(col, subcolumn) && hasSearch){
          _generateSearch(drilldown, col);
        }

        // Generate data indexes on CTA's
        var k2 = 0;
        var list = col.querySelector('.' + ddListClass);
        list.setAttribute('id', ddListClass + '-' + k);

        [].forEach.call(list.querySelectorAll('#' + ddListClass + '-' + k + ' > .' + item + ' > .' + callToAction), function(cta){
          cta.setAttribute(ddIndexDepthAtt, depth);
          cta.setAttribute(ddIndexAtt, k2);
          cta.setAttribute('aria-hidden', 'false');
          k2++;
        });

        k2++;
      });


      function _generateCoverCta(col, depth){
        var coverCTA = document.createElement("button");
          coverCTA.setAttribute("type","button");
          coverCTA.setAttribute(ddCoverAtt,depth);
          addClass(coverCTA, ddCtaCoverClass);

          var coverCTASpan = document.createElement("span");
              coverCTASpan.innerHTML = "Terug naar niveau " + depth;

          coverCTA.appendChild(coverCTASpan);
        col.appendChild(coverCTA);
      }

      function _generateBackCta(col){
        var backCTA = document.createElement("button");
          backCTA.setAttribute("type","button");
          backCTA.setAttribute(ddBackAtt,"");
          addClass(backCTA, backCallToAction);

          var backCTASpan = document.createElement("span");
              backCTASpan.innerHTML = "Sluit submenu";

          backCTA.appendChild(backCTASpan);

        var header = col.querySelector('.' + subcolumnHeader);
        if(header !== null)
            header.appendChild(backCTA);
      }

      function _generateSearch(drilldown, col){

        var header = col.querySelector('.' + subcolumnHeader);
        if(header !== null){
          var placeholder = drilldown.getAttribute(ddSearchPhAtt);
          if(placeholder == null){
            placeholder = "Zoek";
          }
          var inputWrapper = document.createElement('div');
            addClass(inputWrapper, ddSubcolumnSearchClass);

            var input = document.createElement('input');
                addClass(input, ddSubcolumnSearchInputClass);
                addClass(input, ddInputFieldClass);
                addClass(input, ddInputFieldBlockClass);
                input.setAttribute('type', 'text');
                input.setAttribute('aria-label', placeholder);
                input.setAttribute('placeholder', placeholder);
                input.setAttribute(ddInputAtt, '');

                inputWrapper.appendChild(input);

            header.appendChild(inputWrapper);
        }else{
          // Generate warning
          console.warn(vl.warnings.drilldown.search);
        }
      }
    }
  };

  vl.drilldown.dressAll = function() {

    var elements = document.querySelectorAll('['+ddAtt+']:not(.' + ddBoundClass + ')');

    [].forEach.call(elements, function (drilldown) {
      vl.drilldown.dress(drilldown);
    });
  };

  vl.drilldown.dressAll();

})();
