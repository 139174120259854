/**
 * Dress the sticky bar.
 */

vl.stickyBar = {};
vl.stickyBar.dress;
vl.stickyBar.dressAll;


(function () {

  var sbClass = 'js-' + vl.ns + 'sticky-bar',
      sbBoundClass = sbClass + '-bound',
      sbVisibleClass = sbClass + '-visible',
      sbIDAtt = 'data-sticky-bar-id',
      sbTriggerAtt = 'data-sticky-bar-trigger';


  vl.stickyBar.dress = function(el) {

    var latestKnownScrollY = 0,
        ticking = false;

    // only on medium or up
    if(vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall' || hasClass(el, sbBoundClass)){
      return;
    }

    addClass(el, sbBoundClass);

    var stickyTrigger = document.querySelector('[' + sbIDAtt + '="' + el.getAttribute(sbTriggerAtt) + '"]');


    window.addEventListener('scroll', onScroll, false);

    onScroll();

    function onScroll() {
      latestKnownScrollY = window.pageYOffset;
      requestTick();
    }

    function requestTick() {
      if(!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    }


    function update() {
      ticking = false;
      var currentScrollY = latestKnownScrollY;

      dressStickyBar(el);
    }

    function dressStickyBar(el) {
      var base = document.querySelector('.js-iwgh3-bc') || document.querySelector('.iwgh2-bar') || document.querySelector('head');
      // if there is a sticky trigger
      var stickyBarTop = base.getBoundingClientRect().bottom;
      if(!stickyTrigger || stickyTrigger.getBoundingClientRect().bottom < stickyBarTop) {
        // show the sticky bar
        addClass(el, sbVisibleClass);
        el.style.top = stickyBarTop + 'px';
      }else if (stickyTrigger.getBoundingClientRect().top > stickyBarTop) {
        removeClass(el, sbVisibleClass);
      }
    }
  };


  vl.stickyBar.dressAll = function() {
    var elements = document.querySelectorAll('.' + sbClass + ':not(.'+sbBoundClass+')');
    [].forEach.call(elements, function (el) {
      vl.stickyBar.dress(el);
    });
  };

  vl.stickyBar.dressAll();

})();


// TO DO: might be usefull to provide the option to give a custom selector for the base.
