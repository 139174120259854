/**
 * Create a tag input by data-attribute
 */

vl.heronavigation = {};
vl.heronavigation.dress;
vl.heronavigation.dressAll;

(function () {

  var hnClass                   = 'js-' + vl.ns + 'hero-navigation',
      hnBoundClass              = 'js-' + vl.ns + 'hero-navigation-bound',
      hnListItemClass           = 'js-' + vl.ns + 'hero-navigation-listitem',
      hnListListitemActiveClass =         vl.ns + 'hero-navigation__list__listitem--active',
      hnLinkClass               = 'js-' + vl.ns + 'hero-navigation-link',
      hnBgClass                 = 'js-' + vl.ns + 'hero-navigation-background',
      hnDefaultLinkClass        = 'js-' + vl.ns + 'hero-navigation-default-link',
      hnBgActiveClass           =         vl.ns + 'hero-navigation__background--active';


  vl.heronavigation.dress = function(nav) {

    var elements      = nav.getElementsByClassName(hnListItemClass),
        links         = nav.getElementsByClassName(hnLinkClass),
        backgrounds   = nav.getElementsByClassName(hnBgClass),
        activeslide;

    function changeSlide(){
      //Change background of slide
      [].forEach.call(backgrounds, function(bg) {
        removeClass(bg, hnBgActiveClass);
      });
      var activebg = nav.querySelector('[data-' + vl.ns + 'hero-index="' + activeslide + '"]');
      addClass(activebg, hnBgActiveClass);
    }

    [].forEach.call(links, function (element) {
      dressHeroNavLink(element);
    });

    // trigger default element
    var defaultLink = nav.getElementsByClassName(hnDefaultLinkClass)[0];
    if(defaultLink) {
      fireEvent(defaultLink, 'mouseenter');
    } else {
      // if no default given, select a random link
      var rand = Math.floor(Math.random() * links.length);
      fireEvent(links[rand], 'mouseenter');
    }

    function dressHeroNavLink(element) {

      element.addEventListener('mouseenter', activateSlide);
      element.addEventListener('focus', activateSlide);

      function activateSlide(event) {
        var listitem = event.target.parentNode;

        [].forEach.call(elements, function (el2) {
          removeClass(el2, hnListListitemActiveClass);
        });

        var index = listitem.getAttribute('data-' + vl.ns + 'hero-index');
        activeslide = index;
        addClass(listitem, hnListListitemActiveClass);

        changeSlide();
      }
    }
  };

  vl.heronavigation.dressAll = function() {
    var navigations = document.querySelectorAll('.' + hnClass + ':not(.' + hnBoundClass + ')');
    [].forEach.call(navigations, function (element) {
      vl.heronavigation.dress(element);
    });
  };

  // Initiate
  vl.heronavigation.dressAll();

})();
