/**
 * Lightbox
 **/

vl.lightbox = {};
vl.lightbox.dressAll;

(function() {

    var lightboxClass = 'js-' + vl.ns + 'lightbox',
        lightboxCaptionClass = 'js-' + vl.ns + 'lightbox-caption',
        pswpClass = vl.ns + 'pswp',
        pswpBGClass = vl.ns + 'pswp__bg',
        pswpScrollClass = vl.ns + 'pswp__scroll-wrap',
        pswpContainerClass = vl.ns + 'pswp__container',
        pswpItemClass = vl.ns + 'pswp__item',
        pswpUIClass = vl.ns + 'pswp__ui',
        pswpUIHiddenClass = vl.ns + 'pswp__ui--hidden',
        pswpTopBarClass = vl.ns + 'pswp__top-bar',
        pswpCounterClass = vl.ns + 'pswp__counter',
        pswpButtonClass = vl.ns + 'pswp__button',
        pswpButtonCloseClass = vl.ns + 'pswp__button--close',
        pswpButtonZoomClass = vl.ns + 'pswp__button--zoom',
        pswpPreloaderClass = vl.ns + 'pswp__preloader',
        pswpPreloaderIconClass = vl.ns + 'pswp__preloader__icn',
        pswpPreloaderCutClass = vl.ns + 'pswp__preloader__cut',
        pswpPreloaderDonutClass = vl.ns + 'pswp__preloader__donut',
        pswpShareModalClass = vl.ns + 'pswp__share-modal',
        pswpShareModalHiddenClass = vl.ns + 'pswp__share-modal--hidden',
        pswpSingleTapClass = vl.ns + 'pswp__single-tap',
        pswpShareTooltipClass = vl.ns + 'pswp__share-tooltip',
        pswpButtonLeftClass = vl.ns + 'pswp__button--arrow--left',
        pswpButtonRightClass = vl.ns + 'pswp__button--arrow--right',
        pswpCaptionClass = vl.ns + 'pswp__caption',
        pswpCaptionCenterClass = vl.ns + 'pswp__caption__center',
        body = document.getElementsByTagName("body")[0],
        dataPrefix = 'data-' + vl.ns,
        lbItemAtt   = dataPrefix + 'lightbox-item',
        lbSizeAtt   = dataPrefix + 'size';


    vl.lightbox.dressAll = function(gallerySelector) {

        var lb = document.querySelector(gallerySelector);
        if (lb == null || lb == undefined)
            return false;

        var pswpElement = document.querySelector('.' + pswpClass); // If pswp container is available do nothing, else generate the necessary elements
        if (pswpElement == null || pswpElement == undefined)
            pswpElement = generateGallery();

        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {

            var thumbElements = el.querySelectorAll('['+lbItemAtt+']'),
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            [].forEach.call(thumbElements, function(thumb) {

                // linkEl = figureEl.querySelector('a');
                size = thumb.getAttribute(lbSizeAtt).split('x');

                // create slide object
                item = {
                    src: thumb.getAttribute('href'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                // Figcaption
                var figcaption = thumb.querySelector('.' + lightboxCaptionClass);
                if (figcaption) {
                    item.title = figcaption.innerHTML;
                }

                var image = thumb.querySelector('img');
                if (image) {
                    item.msrc = image.getAttribute('src');
                }

                item.el = thumb; // save link to element for getThumbBoundsFn
                items.push(item);
            });
            return items;
        };


        // // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            e.preventDefault();

            var eTarget = e.target || e.srcElement;

            var clickedListItem = eTarget;
            if(!eTarget.hasAttribute(lbItemAtt)) {
                clickedListItem = eTarget.closest('['+lbItemAtt+']');
            }

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.closest('.' + lightboxClass),
                childNodes = clickedGallery.querySelectorAll('['+lbItemAtt+']'),
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            (index >= 0 ? openPhotoSwipe(index, clickedGallery) : null); // open PhotoSwipe if valid index found

            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        // var photoswipeParseHash = function() {
        //     var hash = window.location.hash.substring(1),
        //     params = {};

        //     if(hash.length < 5) {
        //         return params;
        //     }

        //     var vars = hash.split('&');
        //     for (var i = 0; i < vars.length; i++) {
        //         if(!vars[i]) {
        //             continue;
        //         }
        //         var pair = vars[i].split('=');
        //         if(pair.length < 2) {
        //             continue;
        //         }
        //         params[pair[0]] = pair[1];
        //     }

        //     if(params.gid) {
        //         params.gid = parseInt(params.gid, 10);
        //     }

        //     return params;
        // };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
            var gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);
            options = {
                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),
                history: false,
                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName('img')[0]; // find thumbnail
                    var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    if (thumbnail) {
                        var rect = thumbnail.getBoundingClientRect();
                        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                    } else
                        return false;
                },
                arrowKeys: true,
                showHideOpacity: true
            };

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (var j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);
        var i = 0;
        [].forEach.call(galleryElements, function(galleryEl) {
            galleryEl.setAttribute('data-pswp-uid', i + 1);
            galleryEl.onclick = onThumbnailsClick;
            i++;
        });

        // // Parse URL and open gallery if it contains #&pid=3&gid=1
        // var hashData = photoswipeParseHash();
        // if(hashData.pid && hashData.gid) {
        //     openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
        // }

        function generateGallery() {

            // pswp
            var pswp = document.createElement('div');
            addClass(pswp, pswpClass);
            pswp.setAttribute('tabindex', '-1');
            pswp.setAttribute('role', 'dialog');
            pswp.setAttribute('aria-hidden', 'true');
            body.appendChild(pswp);

            // BG
            var bg = document.createElement('div');
            addClass(bg, pswpBGClass);
            pswp.appendChild(bg);

            // Scroll wrap
            var scrollwrap = document.createElement('div');
            addClass(scrollwrap, pswpScrollClass);
            pswp.appendChild(scrollwrap);

            // Container
            var container = document.createElement('div');
            addClass(container, pswpContainerClass);
            scrollwrap.appendChild(container);

            // Item
            var item = document.createElement('div');
            addClass(item, pswpItemClass);
            var item2 = item.cloneNode(true);
            var item3 = item.cloneNode(true);
            container.appendChild(item);
            container.appendChild(item2);
            container.appendChild(item3);

            // UI
            var ui = document.createElement('div');
            addClass(ui, pswpUIClass);
            addClass(ui, pswpUIHiddenClass);
            scrollwrap.appendChild(ui);

            // Top bar
            var topbar = document.createElement('div');
            addClass(topbar, pswpTopBarClass);
            ui.appendChild(topbar);

            // Counter
            var counter = document.createElement('div');
            addClass(counter, pswpCounterClass);
            topbar.appendChild(counter);

            // Close button
            var closeButton = document.createElement('button');
            closeButton.setAttribute('type', 'button');
            closeButton.setAttribute('title', 'Sluiten (Esc)');
            addClass(closeButton, pswpButtonClass);
            addClass(closeButton, pswpButtonCloseClass);
            topbar.appendChild(closeButton);

            // Zoom button
            var zoomButton = document.createElement('button');
            zoomButton.setAttribute('type', 'button');
            zoomButton.setAttribute('title', 'Zoom in/uit');
            addClass(zoomButton, pswpButtonClass);
            addClass(zoomButton, pswpButtonZoomClass);
            topbar.appendChild(zoomButton);

            // Preloader
            var preloader = document.createElement('div');
            addClass(preloader, pswpPreloaderClass);
            topbar.appendChild(preloader);

            var preloaderIcon = document.createElement('div');
            addClass(preloaderIcon, pswpPreloaderIconClass);
            preloader.appendChild(preloaderIcon);

            var preloaderCut = document.createElement('div');
            addClass(preloaderCut, pswpPreloaderCutClass);
            preloaderIcon.appendChild(preloaderCut);

            var preloaderDonut = document.createElement('div');
            addClass(preloaderDonut, pswpPreloaderDonutClass);
            preloaderCut.appendChild(preloaderDonut);

            // Share modal
            var shareModal = document.createElement('div');
            addClass(shareModal, pswpShareModalClass);
            addClass(shareModal, pswpShareModalHiddenClass);
            addClass(shareModal, pswpSingleTapClass);
            ui.appendChild(shareModal);

            var shareTooltip = document.createElement('div');
            addClass(shareTooltip, pswpShareTooltipClass);
            shareModal.appendChild(shareTooltip);

            // left button
            var leftButton = document.createElement('button');
            leftButton.setAttribute('type', 'button');
            leftButton.setAttribute('title', 'Vorige (Pijltje naar links)');
            addClass(leftButton, pswpButtonClass);
            addClass(leftButton, pswpButtonLeftClass);
            ui.appendChild(leftButton);

            // right button
            var rightButton = document.createElement('button');
            rightButton.setAttribute('type', 'button');
            rightButton.setAttribute('title', 'Volgende (Pijltje naar rechts)');
            addClass(rightButton, pswpButtonClass);
            addClass(rightButton, pswpButtonRightClass);
            ui.appendChild(rightButton);

            // Caption
            var caption = document.createElement('div');
            addClass(caption, pswpCaptionClass);
            ui.appendChild(caption);

            var captionCenter = document.createElement('div');
            addClass(captionCenter, pswpCaptionCenterClass);
            caption.appendChild(captionCenter);

            return pswp;
        }
    };

    vl.lightbox.dressAll('.' + lightboxClass);

})();