/**
 * Show content when a linked radio / checkbox is checked
 */

vl.input = vl.input || {};
vl.input.showOnChecked = {};
vl.input.showOnChecked.dress;
vl.input.showOnChecked.dressAll;

vl.input.showOnCheckedAll; // Deprecated

 (function () {

  var socClass = 'js-' + vl.ns + 'show-checked',
      socBoundClass = socClass + '-bound',
      socOpenCLass = socClass + '--open',
      socBoundAtt = 'data-' + vl.ns + 'show-checked-bound',
      socTargetAtt = 'data-' + vl.ns + 'show-checked-target',
      socTriggerAtt = 'data-' + vl.ns + 'show-checked-trigger';



  vl.input.showOnChecked.dress = function (element) {
    addClass(element, socBoundClass);
    // init without event
    showChecked(element);

    // if the target is a radio-button, trigger the change event on the other radio-buttons in the group
    if(element.type === "radio"){
      var relatedRadios = document.getElementsByName(element.name);
      [].forEach.call(relatedRadios, function(radio) {

        if(!radio.hasAttribute(socBoundAtt)) {
          radio.addEventListener('change', function (e) {
            var relatedRadios = document.getElementsByName(e.target.name);
            [].forEach.call(relatedRadios, function (radio) {
              if(radio.hasAttribute(socTargetAtt)) {
                showChecked(radio);
              }
            });
          });
          radio.setAttribute(socBoundAtt, true);
        }
      });
    }else {
      element.addEventListener('change', function (e) {
        e.preventDefault();
        showChecked(e.target);
      }, false);
    }

    function showChecked (el){
      var target = document.querySelectorAll('[' + socTriggerAtt + '="' + el.getAttribute(socTargetAtt) + '"]')[0];

      if(el.checked){
          addClass(target, socOpenCLass);
      }else{
          removeClass(target, socOpenCLass);
      }
    }
  };

  vl.input.showOnChecked.dressAll = function () {
    var elements = document.querySelectorAll('[' + socTargetAtt + ']:not(.' + socBoundClass + ')');

    [].forEach.call(elements, function (element) {
      vl.input.showOnChecked.dress(element);
    });
  };

  vl.input.showOnCheckedAll = function () {
    console.warn(vl.warnings.v2_2_1.showOnCheckedAll);
    vl.input.showOnChecked.dressAll();
  };

  // Initiate
  vl.input.showOnChecked.dressAll();

})();
