/**
 * Lazyload images & iframes
 */

vl.lazyload = {};
vl.lazyload.dressAll;

// Global lazysizes configuration

// Disable autoload
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

window.lazySizesConfig.customMedia = {
    '--xsmall': '(max-width: 500px)',
    '--small': '(max-width: 767px)',
    '--medium': '(max-width: 1023px)',
    '--large': '(max-width: 1600px)',
};

window.lazySizesConfig.lazyClass        = 'js-' + vl.ns + 'lazyload';
window.lazySizesConfig.loadingClass     = 'js-' + vl.ns + 'lazyloading';
window.lazySizesConfig.loadedClass      = 'js-' + vl.ns + 'lazyloaded';

(function(){

  var llBoundClass    = 'js-' + vl.ns + 'lazyload-bound',
      llWrapperClass  = 'js-' + vl.ns + 'lazyload-wrapper',
      llAtt           = 'data-' + vl.ns + 'lazyload',
      llBlurAtt       = 'data-' + vl.ns + 'blur';

  vl.lazyload.dressAll = function() {
    var elements = document.querySelectorAll('['+llAtt+']:not(.' + llBoundClass + ')');
    [].forEach.call(elements, function(el){
      addClass(el, llBoundClass);
      if(!hasClass(el, window.lazySizesConfig.lazyClass))
        addClass(el, window.lazySizesConfig.lazyClass);

      // Add wrapper around image element for blur effect
      if(el.hasAttribute(llBlurAtt)){
        var wrapper = document.createElement('div');
        addClass(wrapper, llWrapperClass);
        if(el.parentNode.nodeName == "PICTURE"){
          wrap(el.parentNode, wrapper);
        }else{
          wrap(el, wrapper);
        }
      }
    });

    lazySizes.init();
  };

  // Initiate
  vl.lazyload.dressAll();
})();

