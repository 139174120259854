  /**
 * Autocomplete to be used on an input field
 */

vl.autocomplete = {};
vl.autocomplete.dress;

(function () {
  var dfGeolocationPlaceholder        = 'Mijn locatie',
      dfGeolocationLoadingPlaceholder = 'Locatie ophalen...',
      dfEmptyStateTitle               = 'Geen resultaten',

      acClass                         = vl.ns + 'autocomplete',
      acBoundClass                    = 'js-' + vl.ns + 'autocomplete',

      acListClass                     = vl.ns + 'autocomplete__list',
      acListWrapperClass              = vl.ns + 'autocomplete__list-wrapper',

      acCtaClass                      = vl.ns + 'autocomplete__cta',
      acCtaFocusClass                 = vl.ns + 'autocomplete__cta--focus',
      acCtaTitleClass                 = vl.ns + 'autocomplete__cta__title',
      acCtaSubtitleClass              = vl.ns + 'autocomplete__cta__sub',
      acCtaTitleLocationClass         = vl.ns + 'autocomplete__cta__title--location',

      acItemClass                     = vl.ns + 'autocomplete__item',
      acLoaderClass                   = vl.ns + 'autocomplete__loader',

      acAlertClass                    = vl.ns + 'autocomplete__alert',
      acAlertTitleClass               = vl.ns + 'autocomplete__alert__title',
      acAlertSubtitleClass            = vl.ns + 'autocomplete__alert__subtitle',

      dataPrefix                      = 'data-' + vl.ns,
      acRecordAtt                     = dataPrefix + 'record',
      acShowAtt                       = dataPrefix + 'show',
      acFocusAtt                      = dataPrefix + 'focus',
      acLoaderAtt                     = dataPrefix + 'loader',
      acLoadingAtt                    = dataPrefix + 'loading',
      acIndexAtt                      = dataPrefix + 'index',
      acGeoAtt                        = dataPrefix + 'geolocation',
      acValueAtt                      = dataPrefix + 'value',
      acAtt                           = dataPrefix + 'autocomplete',
      acIdAtt                         = dataPrefix + 'id',
      acInputAtt                      = dataPrefix + 'input',
      acContentAtt                    = dataPrefix + 'content',
      acRecordsAtt                    = dataPrefix + 'records',
      acMinCharsAtt                   = dataPrefix + 'min-chars';

  vl.autocomplete.dress = function(acField, params) {
    addClass(acField, acBoundClass);

    params.noResultsFound = params.noResultsFound || {};

    /** AC specific variables **/
    var arrVars      = generateAutocomplete(acField),
        inputVal,
        lastVal,
        curIndex     = 0,
        ntChars      = arrVars.ntChars,
        acInput      = arrVars.acInput,
        acId         = arrVars.acId,
        acContent    = arrVars.acContent,
        acList       = arrVars.acList,
        acFocusElems = acField.querySelectorAll('['+acFocusAtt+']'),
        acRecords    = acList.querySelectorAll('['+ acRecordAtt +']'),
        acLoader     = acField.querySelector('['+acLoaderAtt+']');

    acInput.addEventListener('keydown', acInputKeydownHandler);
    acInput.addEventListener('keyup',   acInputKeyupHandler);
    [].forEach.call(acFocusElems, function(acFocusElem){
      acFocusElem.addEventListener('blur', acFocusElemBlurHandler);
    });

    /**
     * acInputKeydownHandler
     * @param  {keydown event} e
     */
    function acInputKeydownHandler(e){
      // Stop action on enter, arrow down & up
      switch(e.keyCode){
        case 13: case 38: case 40:
          e.preventDefault();
        break;
      }
    };

    /**
     * acInputKeyup
     * @param  {keyup event} e
     */
    function acInputKeyupHandler(e){
      inputVal = acInput.value;
      switch(e.keyCode){
        case 13: // Used to hijack the "enter" key when selecting an element in the dropdown
          e.preventDefault();
          var target = acList.querySelector('['+acRecordAtt+']['+acIndexAtt+'="'+ curIndex +'"]');
          if(target){ // Trigger click when it's a geolocation record
            if(target.hasAttribute(acGeoAtt))
              target.click();
          }
          setAcContentState(acField, "hide");
        break;
        case 27: // "Esc" key
          e.preventDefault();
          acInput.setAttribute('aria-expanded', false);
          setAcContentState(acField, "hide");
        break;
        case 40: // "arrow down" key
          e.preventDefault();
          moveFocus("down");
        break;
        case 38: // "arrow up" key
          e.preventDefault();
          moveFocus("up");
        break;
        default:
          if(this.value !== lastVal){ // Check if value is different
            if(this.value.length >= ntChars){ // Check if value is longer than the min amount of characters
              curIndex = 0;
              setPreloader(acLoader, acField, true); // Start preloader
              var obj = { // Retrieve data
                searchStr: acInput.value,
                callbackFn: cb
              };
              params.callbackFn(acField, obj);
              function cb(jsonData){
                setPreloader(acLoader, acField, false); // Stop preloader records from data
                if(jsonData !== null && typeof jsonData === 'object'){ // Generate records from data
                  generateAutocompleteRecords(acField, jsonData, params);
                }else if(jsonData == false){
                  generateEmptyRecord(acField, params);
                }
              }
            }else{
              setAcContentState(acField, "hide");
            }
          }
          // Check if value is changed
          lastVal = this.value;
        break;
      }
    };

    /**
     * moveFocus
     * @param  {string} direction ["down" or "up"]
     */
    function moveFocus(direction){
      if(acInput.getAttribute('aria-expanded') == "true"){
        var countElems = acList.querySelectorAll('['+ acRecordAtt +']').length;

        switch(direction){
          case "down":
            if(curIndex < countElems){
              curIndex++;
              setValue();
            }
          break;

          case "up":
            if(curIndex > 1){
              curIndex--;
              setValue();
            }
          break;
        }

        function setValue(){
          [].forEach.call(acList.querySelectorAll('['+acRecordAtt+']'), function(item){ removeClass(item, acCtaFocusClass) });
          var target = acList.querySelector('['+acRecordAtt+']['+acIndexAtt+'="'+ curIndex +'"]');
          addClass(target, acCtaFocusClass);
          setInputfieldValue(acField, acInput, target.getAttribute(acValueAtt));
          target.focus();
          acInput.focus();
        }
      }
    }

    /**
     * acFocusElemBlurHandler
     * @param  {blur event} e
     */
    function acFocusElemBlurHandler(e){
      window.setTimeout(function(){
        var parent = document.activeElement.closest('['+acAtt+']['+acIdAtt+'="' + acId + '"]');
        if(parent === null){
          setVisibilityAttributes(acContent, false, true);
          acInput.setAttribute('aria-expanded', false);
        }
      }, 1);
    };

  };

  /*
  /**
   * generateAutocompleteRecords
   * @param  {DOM element} acField
   * @param  {JSON object} data  {*title: "", subtitle: "", *value: ""}
   * @param  {JSON object} params
   */
  function generateAutocompleteRecords(acField, data, params) {

    if(data !== null){
      var ntChars       = acField.getAttribute('['+acInputAtt+']'),
          acInput       = acField.querySelector('['+acInputAtt+']'),
          acContent     = acField.querySelector('['+acContentAtt+']'),
          acList        = acField.querySelector('['+acRecordsAtt+']'),
          acLoader      = acField.querySelector('['+acLoaderAtt+']');

      /* #1 Remove current children if existent */
      var acList = acField.querySelector('['+acRecordsAtt+']');
      while (acList.firstChild) {
          acList.removeChild(acList.firstChild);
      }

      /* #2 Generate children based on the data */
      var c = 1;

      if(params.hasGeolocation){
        ("https:" !== document.location.protocol ? console.warn(vl.warnings.default.autocomplete.geolocation) : _generateGeolocationRecord() );
        c++;
      }


      function _generateGeolocationRecord(){
        if('geolocation' in navigator){
          (params.geolocation.placeholder == undefined ? params.geolocation.placeholder = dfGeolocationPlaceholder : null);

          var acRecord = document.createElement('li');
          addClass(acRecord, acItemClass);
          acRecord.setAttribute('role','option');
          acRecord.innerHTML = '<a href="#" class="' + acCtaClass + '" tabindex="-1" '+acIndexAtt+'="' + c + '" '+acRecordAtt+' '+acFocusAtt+' '+acGeoAtt+'>' +
                                  '<span class="' + acCtaTitleClass + ' ' + acCtaTitleLocationClass + '">' + params.geolocation.placeholder + '</span>' +
                               '</a>';

          acRecord.addEventListener('click', function(e){
            e.preventDefault();
            _requestLocation();
          });

          acRecord.addEventListener('keydown', function(e){
            e.preventDefault();
            (e.keyCode == 13 ? _requestLocation() : null);
          });

          acList.appendChild(acRecord);
        }

        function _requestLocation(){

          var options = {
            enableHighAccuracy: false, // enableHighAccuracy = should the device take extra time or power to return a really accurate result, or should it give you the quick (but less accurate) answer?
            timeout: 5000, // timeout = how long does the device have, in milliseconds to return a result?
            maximumAge: 0 // maximumAge = maximum age for a possible previously-cached position. 0 = must return the current position, not a prior cached position
          };

          // Enable preloader, close the autocomplete
          setPreloader(acLoader, acField, true);
          var prevPlaceholder = acInput.getAttribute('placeholder');
          setVisibilityAttributes(acContent, false, true);
          acInput.setAttribute('aria-expanded', false);
          acInput.setAttribute('disabled', true);
          acInput.setAttribute('placeholder', dfGeolocationLoadingPlaceholder);
          acInput.value = '';

          navigator.geolocation.getCurrentPosition(success, error, options);

          function success(pos){
            setPreloader(acLoader, acField, false);

            var geocoords = {
              lng: pos.coords.longitude,
              lat: pos.coords.latitude
            };
            if(params.geolocation.return !== undefined){
              switch(params.geolocation.return){

                case "postal_code":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK"){
                      data.results.forEach(function(element){
                        element.address_components.forEach(function(element2){
                          element2.types.forEach(function(element3){
                            switch(element3){
                              case 'postal_code':
                                geolocationCallbackReturn(element2.long_name);
                                return;
                              break;
                            }
                          });
                        });
                      });
                    }
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                case "city":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK"){
                      data.results.forEach(function(element){
                        element.address_components.forEach(function(element2){
                          element2.types.forEach(function(element3){
                            switch(element3){
                              case 'locality':
                                geolocationCallbackReturn(element2.long_name);
                                return;
                              break;
                            }
                          });
                        });
                      });
                    }
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                case "formatted_address":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK")
                      geolocationCallbackReturn(data.results[0].formatted_address);
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                default:
                  // Custom functie
                  if (typeof params.geolocation.return === "function")
                    params.geolocation.return(geocoords, geolocationCallbackReturn);
                  else
                    console.warn(vl.warnings.autocomplete.geolocationCallback);
              }
            }
            else{
              console.warn(vl.warnings.autocomplete.geolocationCallback);
              acInput.removeAttribute('disabled');
              acInput.focus();
              acInput.setAttribute('placeholder', prevPlaceholder);
            }

            function geolocationCallbackReturn(str){
              if(str !== false){
                acInput.value = str;
              }
              acInput.removeAttribute('disabled');
              acInput.focus();
              acInput.setAttribute('placeholder', prevPlaceholder);
            }
          }

          function error(err){
            setPreloader(acLoader, acField, false);
            acInput.removeAttribute('disabled');
            acInput.focus();
            acInput.setAttribute('placeholder', prevPlaceholder);

            return false;
          }
        }
      }

      _generateRecords();

      function _generateRecords(){

        [].forEach.call(data, generateRecord);
        function generateRecord(obj){
          // Generate list item
          var acRecord = document.createElement('li');
          addClass(acRecord, acItemClass);
          acRecord.setAttribute('role','option');

          // Append html to the record
          if(typeof obj.subtitle !== "undefined"){
          acRecord.innerHTML = '<a class="'+ acCtaClass +'" href="#" tabindex="-1" '+acIndexAtt+'="' + c + '" '+acRecordAtt+' '+acFocusAtt+' '+acValueAtt+'="' + obj.value + '">' +
                                  '<span class="' + acCtaSubtitleClass + '">' + obj.subtitle + '</span>' +
                                  '<span class="' + acCtaTitleClass + '">' + obj.title + '</span>' +
                               '</a>';
          }else{
            acRecord.innerHTML = '<a class="'+ acCtaClass +'" href="#" tabindex="-1" '+acIndexAtt+'="' + c + '" '+acRecordAtt+' '+acFocusAtt+' '+acValueAtt+'="' + obj.value + '">' +
                                  '<span class="' + acCtaTitleClass + '">' + obj.title + '</span>' +
                                 '</a>';
          }

          /* #2.1 Click event for child */
          acRecord.addEventListener('click', function(e){
            e.preventDefault();
            var rec = acRecord.querySelector('['+acRecordAtt+']');
            setInputfieldValue(acField, acInput, rec.getAttribute(acValueAtt));
            setVisibilityAttributes(acContent, false, true);
            acInput.setAttribute('aria-expanded', false);
          });

          acList.appendChild(acRecord);
          c++;
        }
      }

      /* #3 Show content */
      setVisibilityAttributes(acContent, true, false);
      acInput.setAttribute('aria-expanded', "true");
    }
  };

  /**
   * generateEmptyRecord
   * @param  {DOM element} acField
   * @param  {JSON Object} params
   */
  function generateEmptyRecord(acField, params) {
      var acContent     = acField.querySelector('['+acContentAtt+']'),
          acInput       = acField.querySelector('['+acInputAtt+']'),
          acList        = acField.querySelector('['+acRecordsAtt+']');

      while (acList.firstChild) {
          acList.removeChild(acList.firstChild);
      }

      if(!params.noResultsFound){
        setVisibilityAttributes(acContent, false, true);
        acInput.setAttribute('aria-expanded', "false");
        return;
      }else
        if(params.noResultsFound.title == undefined)
          params.noResultsFound.title = dfEmptyStateTitle;

      var acRecord = document.createElement('li');
          addClass(acRecord, acItemClass);
          acRecord.setAttribute('role','option');

          if(params.noResultsFound.subtitle == undefined){
            acRecord.innerHTML = '<div class="' + acAlertClass + '">' +
                                    '<span class="' + acAlertTitleClass + '">' + params.noResultsFound.title + '</span>' +
                                 '</div>';
          }else{
            acRecord.innerHTML = '<div class="' + acAlertClass + '">' +
                                    '<span class="' + acAlertTitleClass + '">' + params.noResultsFound.title + '</span>' +
                                    '<span class="' + acAlertSubtitleClass + '">' + params.noResultsFound.subtitle + '</span>' +
                                 '</div>';
          }
      acList.appendChild(acRecord);

      /* #3 Show content */
      setVisibilityAttributes(acContent, true, false);
      acInput.setAttribute('aria-expanded', "true");
  };

  /**
   * setVisibilityAttributes
   * @param {DOM element} field
   * @param {Boolean} dataShow
   * @param {Boolean} ariaHidden
   */
  function setVisibilityAttributes(field, dataShow, ariaHidden){
    field.setAttribute(acShowAtt,   dataShow);
    field.setAttribute('aria-hidden', ariaHidden);
  };

  /**
   * setPreloader - Showing/hiding the preloader on the autocomplete input field
   * @param {DOM element}  acLoader
   * @param {DOM element}  acField
   * @param {Boolean} isLoading
   */
  function setPreloader(acLoader, acField, isLoading){
    (isLoading ? setVisibilityAttributes(acLoader, true, false) : setVisibilityAttributes(acLoader, false, true));
    acField.setAttribute(acLoadingAtt, isLoading);
  };

  /**
   * setAcContentState
   * @param {DOM element} acField
   * @param {Boolean} acState
   */
  function setAcContentState(acField, acState){
    var acContent = acField.querySelector('['+acContentAtt+']');
    switch(acState){
      case "show": setVisibilityAttributes(acContent, true, false); break;
      case "hide": setVisibilityAttributes(acContent, false, true); break;
    }
  };

  /**
   * setInputfieldValue
   * @param {DOM element} acField
   * @param {DOM element} acInput
   * @param {string} value
   */
  function setInputfieldValue(acField, acInput, value){
    // Set value
    acInput.value = value;
    fireEvent(acInput, 'vl.autocomplete.hasChanged');
  };

  /**
   * generateAutocomplete
   * @param  {DOM element} acField
   */
  function generateAutocomplete(acField){

    var acId = uniqueId();
    acField.setAttribute(acIdAtt, acId);

    var arr, ntChars = detectCharacterCount(acField);
    var acInput = setAcInput(acField);
    var acLoader = setAcLoader(acField);
    var acContent = setAcContent(acField, acInput);
    var acListWrapper = setAcListWrapper(acContent);
    var acList = setAcList(acListWrapper);

      /* detect charcount */
    function detectCharacterCount(acField){
      if(acField.hasAttribute(acMinCharsAtt) && isNumeric(acField.getAttribute(acMinCharsAtt))){
        return acField.getAttribute(acMinCharsAtt);
      }else{
        return 3;
      }
    }

    /* modify the given input field */
    function setAcInput(acField){
      var acInput = acField.querySelector('input');
      (!acInput.hasAttribute('id') ? acInput.setAttribute('id', 'autocomplete-input-' + uniqueId()) : null);
      acInput.setAttribute('aria-expanded', "false");
      acInput.setAttribute(acFocusAtt, '');
      acInput.setAttribute(acInputAtt, '');
      acInput.setAttribute('autocapitalize', 'off');
      acInput.setAttribute('spellcheck', 'off');
      acInput.setAttribute('autocomplete', 'off');
      acInput.setAttribute('aria-autocomplete', 'list');
      acInput.setAttribute('aria-owns', 'autocomplete-' + acId);
      acInput.setAttribute('aria-controls', 'autocomplete-' + acId);
      acInput.setAttribute('aria-haspopup', 'listbox');

      return acInput;
    }

    function setAcLoader(acField){
        var acLoader = document.createElement("div");
        addClass(acLoader, acLoaderClass);
        acLoader.setAttribute(acShowAtt,'false');
        acLoader.setAttribute(acLoaderAtt,'');
        acLoader.setAttribute('aria-hidden',true);
        acField.appendChild(acLoader);

        return acLoader;
    }

    /* generate the accontent field */
    function setAcContent(acField, acInput){

      var acContent = document.createElement('div');
      addClass(acContent, acClass);
      acContent.setAttribute(acContentAtt, '');
      acContent.setAttribute('aria-hidden', true);
      acContent.setAttribute(acShowAtt, false);
      acContent.setAttribute('aria-labelledby', acInput.getAttribute('id'));
      acContent.setAttribute('id', 'autocomplete-' + acId);
      acField.appendChild(acContent);

      return acContent;
    }

    /* generate the aclistwrapper field */
    function setAcListWrapper(acContent){

      var acListWrapper = document.createElement('div');
      addClass(acListWrapper, acListWrapperClass);
      acContent.appendChild(acListWrapper);

      return acListWrapper;
    }

    /* generate the aclistwrapper field */
    function setAcList(acListWrapper){

      var acList = document.createElement('ul');
      addClass(acList, acListClass);
      acList.setAttribute(acRecordsAtt, '');
      acList.setAttribute('role','listbox');
      acListWrapper.appendChild(acList);

      return acList;
    }

    return {"acId": acId, "acLoader": acLoader, "ntChars": ntChars, "acInput": acInput, "acContent": acContent, "acListWrapper": acListWrapper, "acList": acList};
  };

})();

