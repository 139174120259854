/**
 * Push a fixed element by another element in viewport
 * Written to push helpwidget by global footer
 */
(function () {

  var puClass           = 'js-' + vl.ns + 'push',
      puBoundClass      = puClass + '-bound',
      elements          = document.querySelectorAll('.'+puClass+':not(.' + puBoundClass + ')'),
      ticking           = false
      puByAtt           = 'data-' + vl.ns + 'push-by',
      puSpaceAtt        = 'data-' + vl.ns + 'push-space';

  function onScroll() {
    requestTick();
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }

  function update() {
    // reset the tick so we can
    // capture the next onScroll
    ticking = false;

    [].forEach.call(elements, function (element) {
      var pusher = document.getElementsByClassName(element.getAttribute(puByAtt))[0],
          spacer = parseInt(element.getAttribute(puSpaceAtt)) || 0;

      if(!pusher) {
        console.warn('no pusher found with class '+element.getAttribute(puByAtt));
      }else {
        var space = Math.min(0, pusher.getBoundingClientRect().top - element.getBoundingClientRect().bottom - spacer -  Number(element.style.marginBottom.replace(/[^\d\.\-]/g, ''))) * -1 ;
        element.style.marginBottom = space + 'px';
      }
    });
  }

  // add event listener
  if(elements.length) {
    window.addEventListener('scroll', onScroll, false);
    onScroll();
    [].forEach.call(elements, function (element) {
      addClass(element, puBoundClass);
    });

  }

})();
