/**
 * accordion functionalities
 */

vl.accordion = {};
vl.accordion.dress;
vl.accordion.dressAll;

(function(){
  var accordionClass                = 'js-' + vl.ns + 'accordion',
      accordionOpenClass            = 'js-' + vl.ns + 'accordion--open',
      accordionToggleClass          = 'js-' + vl.ns + 'accordion__toggle',
      accordionToggleBoundClass     = 'js-' + vl.ns + 'accordion-bound';

  /**
   * dress function for the accordion functionality
   * @param  {DOM element} element
   * @return {false}
   */
  vl.accordion.dress = function(element) {
      addClass(element, accordionToggleBoundClass);
      element.addEventListener('click', function(e) {
        e.preventDefault();
        // get the closest accordion parent
        var accordion = this.closest('.' + accordionClass);
        toggleClass(accordion, accordionOpenClass);
      });
  };

  /**
   * dressAll function for the accordion functionality
   * @return {false}
   */
  vl.accordion.dressAll = function() {
    // get all accordion toggle elements
    var elements = document.querySelectorAll('.' + accordionToggleClass + ':not(.' + accordionToggleBoundClass + ')');
    // add functionality to the accordions
    [].forEach.call(elements, function(element) {
      vl.accordion.dress(element);
    });
  };

  /**
   * Initiate dressAll function
   */
  vl.accordion.dressAll();
})();
