// Set global VL element
var vl = {};

// Set namespace
(window.namespace !== undefined ? vl.ns = namespace : vl.ns = "");

(function() {
  "use strict";
  /**
   * Add js class to the HTML
   */
  addClass(document.querySelectorAll('html')[0], 'js');
  removeClass(document.querySelectorAll('html')[0], 'no-js');

})();
