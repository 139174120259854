/**
 * Functional header menu functionality (responsive)
 */

vl.functionalheader = {};
vl.functionalheader.dress;
vl.functionalheader.dressAll;

(function () {

  var fhClass           = 'js-' + vl.ns + 'functional-header-menu',
      fhBoundClass      = 'js-' + vl.ns + 'functional-header-menu-bound',
      fhMenuOpenClass   =         vl.ns + 'functional-header__menu--open',
      fhMenuToggleClass = 'js-' + vl.ns + 'functional-header-menu__toggle';


  vl.functionalheader.dress = function(menu) {
    addClass(menu, fhBoundClass);
    var toggle = menu.getElementsByClassName(fhMenuToggleClass)[0];

    toggle.addEventListener('click', function(e){
      if(hasClass(menu, fhMenuOpenClass))
        removeClass(menu, fhMenuOpenClass);
      else
        addClass(menu, fhMenuOpenClass);
    });
  };

  vl.functionalheader.dressAll = function(){

    var functionalheadermenus = document.querySelectorAll('.' + fhClass + ':not(.' + fhBoundClass + ')');

    [].forEach.call(functionalheadermenus, function (element) {
      vl.functionalheader.dress(element);
    });
  };

  // Initiate
  vl.functionalheader.dressAll();

})();
