/**
 * Input patterns script
 */

vl.input = {};
vl.input.pattern = {};
vl.input.pattern.dress;
vl.input.pattern.dressAll;

(function () {

  var ipBoundClass = 'js-' + vl.ns + 'js-input-pattern-bound';
      patterns = {
        'iban': '{{aa}}{{99}} {{****}} {{9999}} {{999}}{{*}} {{****}} {{****}} {{****}} {{***}}',
        'rrn': '{{99}}.{{99}}.{{99}}-{{999}}.{{99}}',
        'date': '{{99}}.{{99}}.{{9999}}',
        'onr' : '{{9999}}.{{999}}.{{999}}' // ondernemingsnummer, first digit needs to be zero
      };


  // define global function
  vl.input.pattern.dress = function(input) {
    addClass(input, ipBoundClass);
    // get pattern
    var patternID = input.getAttribute('data-' + vl.ns + 'pattern');
    var pattern = patternID;
    if(patternID in patterns){
      pattern = patterns[patternID];
    }

    var formatted = new Formatter(input, {
      'pattern': pattern,
      'persistent': false
    });
  };

  vl.input.pattern.dressAll = function (){

    var inputs = document.querySelectorAll('[data-' + vl.ns + 'pattern]:not(.' + ipBoundClass + ')');

    [].forEach.call(inputs, function(input) {
      vl.input.pattern.dress(input);
    });
  };

  // Initiate
  vl.input.pattern.dressAll();

})();
