vl.scheduler = {};

(function(){

  var scBoundClass = 'js-' + vl.ns + 'scheduler-bound',
        scClass = 'js-' + vl.ns + 'scheduler',
        scOptionClass = scClass + '-option',
        scOptionInputClass = scOptionClass + '__input',
        scOptionTitleClass = scOptionClass + '__title',
        scOptionInfoClass = scOptionClass + '__info',
        scSlotClass = scClass + '-slot',
        scSlotToggleClass = scSlotClass + '__toggle',
        scSlotSelectedClass = vl.ns + 'scheduler-slot--selected',
        scSlotTitleClass = scSlotClass + '__text__title',
        scSlotInfoClass = scSlotClass + '__text__info';

  /*
   * vl.scheduler.dress(element)
   * Dress the scheduler functionality
   * @param 1: ".js-scheduler" DOM element
   */
  vl.scheduler.dress = function(element) {
    addClass(element, scBoundClass);
    // get all inputs in that scheduler
    var options = element.querySelectorAll('.' + scOptionInputClass);
    [].forEach.call(options, function(option) {
        option.addEventListener('change', function(e) {
            var target = e.currentTarget
            if(target.checked) {
                // change the content of the slot
                var scheduler = target.closest('.'+scClass);
                var option = target.closest('.'+scOptionClass);
                var slot = scheduler.querySelector('.'+scSlotClass);
                var title = option.querySelector('.'+scOptionTitleClass);
                var info = option.querySelector('.'+scOptionInfoClass);

                if(title) {
                    scheduler.querySelector('.'+scSlotTitleClass).textContent = title.textContent;
                }
                if(info) {
                    scheduler.querySelector('.'+scSlotInfoClass).textContent = info.textContent;
                }

                addClass(slot, scSlotSelectedClass);
            }
        });
    });

    // delegate click on slot to the button
    var toggles = element.querySelectorAll('.'+scSlotToggleClass);
    [].forEach.call(toggles, function(toggle) {
      toggle.addEventListener('click', function(e) {
        e.stopPropagation();
      });
      // find its slot
      var slot = toggle.closest('.'+scSlotClass);
      if(slot) {
        slot.addEventListener('click', function(e) {
          var target = e.currentTarget;
          var btn = target.querySelector('.'+scSlotToggleClass);
          if(btn) {
            fireEvent(btn, 'click');
          }
        });
      }
    });

  };

  /*
   * vl.scheduler.dressAll()
   * Dress all schedulers in the DOM
   */
  vl.scheduler.dressAll = function() {
    // get all infotext elements
    var elements = document.querySelectorAll('.' + scClass + ':not(.' + scBoundClass + ')');
    // set the text-size for the value of each infotext
    [].forEach.call(elements, function(element) {
      vl.scheduler.dress(element);
    });
  };

  window.addEventListener('load', function() {
    vl.scheduler.dressAll();
  });
})();
