vl.slider = {};
vl.slider.dress;
vl.slider.dressAll;

/**
 * Initiate the javascript slider "Lory"
 */
(function () {
  var slFrameClass      = 'js-' + vl.ns + 'slider__frame',
      slSlidesClass     = 'js-' + vl.ns + 'slider__slides',
      slPreviousClass   = 'js-' + vl.ns + 'slider__previous',
      slNextClass       = 'js-' + vl.ns + 'slider__next',
      slBoundClass      = 'js-' + vl.ns + 'slider-bound',
      slClass           = 'js-' + vl.ns + 'slider',
      gridClass         =         vl.ns + 'grid';

  /*
 * vl.slider.dress(element)
 * Initiates a slider
 * @param 1: ".js-slider" DOM element
 */
  vl.slider.dress = function(slider) {
    addClass(slider, slBoundClass);

    var slides = slider.getElementsByClassName(slSlidesClass)[0];
    var sliderFrame = slider.getElementsByClassName(slFrameClass)[0];

    var flkty = new Flickity( slides, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true
    });

    // transfer grid class if required
    if(hasClass(slides, gridClass)) {
      removeClass(slides, gridClass);
      addClass(flkty.slider, gridClass);
    }

    // only add buttons if they are usefull
    var target = flkty.selectedCell.target;
    // enable / disable previous
    if(target !== flkty.cells[0].target || target !== flkty.getLastCell().target){
      addButtons();
    }

    function addButtons() {

      // Add navigation buttons to the slider
      // previous
      var previousLink = document.createElement('button');
      previousLink.href = "javascript:;";
      previousLink.text = "vorige slide";
      addClass(previousLink, slPreviousClass);
      slider.insertBefore(previousLink, sliderFrame);
      previousLink.addEventListener('click', function(){
        flkty.previous();
      });

      // next
      var nextLink = document.createElement('button');
      nextLink.href = "javascript:;";
      addClass(nextLink, slNextClass);
      insertAfter(nextLink, sliderFrame);
      nextLink.text = "volgende slide";
      nextLink.addEventListener('click', function(){
        var target = flkty.selectedCell.target;
        if(target !== flkty.getLastCell().target) {
          flkty.next();
        }
      });

      function disablePreviousNext (flkty) {

        var target = flkty.selectedCell.target;
        // enable / disable previous
        if(target == flkty.cells[0].target){
          addClass(previousLink, slPreviousClass+'--disabled');
        }else{
          removeClass(previousLink, slPreviousClass+'--disabled');
        }
        // enable / disable next
        if(target == flkty.getLastCell().target){
          addClass(nextLink, slNextClass+'--disabled');
        }else{
          removeClass(nextLink, slNextClass+'--disabled');
        }
      }

      disablePreviousNext(flkty);

      // enable / disable the buttons
      flkty.on( 'cellSelect', function() {
        disablePreviousNext(flkty);
      });
    }
  };

  /*
 * vl.slider.dressAll()
 * Initiates all sliders in the DOM
 */
 vl.slider.dressAll = function() {

  var elements = document.querySelectorAll('.' + slClass + ':not(.' + slBoundClass + ')');

  [].forEach.call(elements, function(element){
    vl.slider.dress(element);
  });
 };

 // Initiate
 vl.slider.dressAll();

})();
