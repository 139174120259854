/**
 * Scrollspy navigation
 * We assume that in a sticky element items with an anchor link should have a scrollspy functionality
 */

vl.scrollspy = {};
vl.scrollspy.dress;
vl.scrollspy.dressAll;

(function () {

  // find all #-links in scrollspy elements
  var ssClass             = 'js-' + vl.ns + 'scrollspy',
      ssActiveClass       = ssClass + '-active',
      ssActiveMobileClass = ssClass + '-mobile--active',
      ssPlaceholderClass  = ssClass + '-placeholder',
      ssCloseClass        = ssClass + '__close',
      ssToggleClass       = ssClass + '__toggle',
      ssToggleFixedClass  = ssClass + '__toggle--fixed',
      ssBoundClass        = ssClass + '-bound',
      ssContentClass      = ssClass + '__content',
      regionClass         = vl.ns + 'region',
      snItemClass         = vl.ns + 'side-navigation__item',
      globalHvisibleClass = 'js-iwgh3-bc--visible',
      body                = document.querySelector('body'),
      ssIDAtt             = 'data-' + vl.ns + 'scrollspy-id',
      ssChildAtt          = 'data-' + vl.ns + 'child',
      ssParentAtt         = 'data-' + vl.ns + 'parent',
      ssMobileAtt         = 'data-' + vl.ns + 'scrollspy-mobile',
      stickyOffsetTopAtt  = 'data-' + vl.ns + 'sticky-offset-top',
      sideNavigation      = vl.ns + 'side-navigation';

  vl.scrollspy.dress = function(scrollspyWrapper) {
    addClass(scrollspyWrapper, ssBoundClass);

    var id = uniqueId();
        scrollspyWrapper.setAttribute(ssIDAtt, id);
    var correspondingRegion = scrollspyWrapper.closest('.' + regionClass),
        scrollspyContentWrapper = correspondingRegion.getElementsByClassName(ssContentClass)[0],
        elements = scrollspyWrapper.querySelectorAll('a[href^="#"]'),
        latestKnownScrollY = 0,
        ticking = false,
        params = {
          offset: 100
        };

    // only add scrollspy if all content is loaded
    [].forEach.call(elements, function(el){
      if(el.hasAttribute(ssChildAtt)){
        el.setAttribute('aria-expanded', 'false');
      }
    });

    // Initiate on small/xsmall breakpoints
    if(vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall') {
      scrollspy_mobile();
    }

    window.addEventListener('scroll', scrollspy, false);
    scrollspy();

    function scrollspy() {
      latestKnownScrollY = window.pageYOffset;
      requestTick();
    };

    function requestTick() {
      if(!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    };

    function update() {
      ticking = false;
      var currentScrollY = latestKnownScrollY;
      [].forEach.call(elements, function(el) {
        checkScrollSpy(el);
      });
    };

    function checkScrollSpy (element) {
      var href = element.getAttribute('href');
      // if the link is an empty #, end here
      if(href === "#" || href === null)
        return;
      // Check if global header breadcrumb is shown
      var hasBreadcrumb = document.getElementsByClassName(globalHvisibleClass)[0];
      var initialOffset = scrollspyWrapper.getAttribute(stickyOffsetTopAtt) || 75;

      if (!hasClass(scrollspyWrapper, sideNavigation)) {
        if (hasBreadcrumb !== null) {
          scrollspyWrapper.style.top = (parseInt(initialOffset) + 41) + "px";
        } else {
          scrollspyWrapper.style.top = initialOffset + "px";
        }
      }

      // END Check if global header breadcrumb is shown

      var target = document.querySelector(href);
      var currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

      var bounds = {
        min: getOffsetTop(target),
        max: getHeight(target) + getOffsetTop(target)
      }

      if(currentScrollPosition > bounds.min && currentScrollPosition < bounds.max){
        var otherItems = scrollspyWrapper.querySelectorAll('.' + snItemClass + ' a');
        [].forEach.call(otherItems, function(el) {
          if(element !== el){
            removeClass(el, ssActiveClass);
            if(el.hasAttribute(ssChildAtt))
              el.setAttribute('aria-expanded', false);
          }
        });
        addClass(element, ssActiveClass);

        // Parentdetection
        var dataParent = element.getAttribute(ssParentAtt);
        var parent = scrollspyWrapper.querySelector('['+ssChildAtt+'="'+ dataParent +'"]');
        if(parent !== null)
          parent.setAttribute('aria-expanded', true);
      }
    };

    function scrollspy_mobile() {
      // Generate close button
      var placeholder = document.createElement('div');
          addClass(placeholder, ssPlaceholderClass);
          wrap(scrollspyWrapper, placeholder);
      var closeBtn = document.createElement('button');

      if(placeholder !== undefined){
            closeBtn.setAttribute('type','button');
            closeBtn.setAttribute('tabindex','0');
            closeBtn.innerHTML = "Navigatie sluiten";
            addClass(closeBtn, ssCloseClass);
            placeholder.appendChild(closeBtn);
      }

      closeBtn.addEventListener('click', function(e){
        e.stopPropagation();
        closePopup();
      });

      // Generate toggle button
      var wrapperHeight = getHeight(scrollspyContentWrapper),
          scrollspyBtnLabel = scrollspyWrapper.getAttribute(ssMobileAtt);

      if(scrollspyContentWrapper !== undefined){
        var openBtn = document.createElement('button');
        openBtn.setAttribute('type','button');
        openBtn.setAttribute('tabindex','0');
        openBtn.setAttribute('aria-expanded', 'false');
        openBtn.innerHTML = scrollspyBtnLabel || "Navigatie";
        addClass(openBtn, ssToggleClass);
        addClass(openBtn, vl.ns + 'button');
        addClass(openBtn, vl.ns + 'button--block');
        scrollspyContentWrapper.appendChild(openBtn);

        openBtn.addEventListener('click', function(e){
          e.stopPropagation();
          openBtn.setAttribute('aria-expanded', true);
          placeholder.setAttribute('tabindex', '0');
          addClass(placeholder, ssActiveMobileClass);
          addClass(body, vl.ns + 'u-no-overflow');
          placeholder.focus();
        });
        // Get offset
        var openBtnOffsetHeight = 0;
        var bt = openBtn;
        while(bt){
           openBtnOffsetHeight += bt.offsetTop;
           bt = bt.offsetParent;
        }
        // Add height to offset
        openBtnOffsetHeight = openBtnOffsetHeight + 30;
        // Add fixed class to toggle
        window.addEventListener('scroll', function(){
          if(window.pageYOffset > openBtnOffsetHeight && window.pageYOffset < (openBtnOffsetHeight + wrapperHeight - document.documentElement.clientHeight))
            addClass(openBtn, ssToggleFixedClass);
          else
            removeClass(openBtn, ssToggleFixedClass);
        }, false);
      }


      [].forEach.call(elements, function(el){
        el.addEventListener('click', function(e){
        if(el.hasAttribute(ssChildAtt)){
            // Close all others
            var otherParents = scrollspyWrapper.querySelectorAll('['+ssChildAtt+']');
            [].forEach.call(otherParents, function(prnt){
              prnt.setAttribute('aria-expanded', 'false');
            });
            el.setAttribute('aria-expanded', 'true');
          }else{
            closePopup();
          }
          e.stopPropagation();
        });
      });

      function closePopup(){
        removeClass(placeholder, ssActiveMobileClass);
        removeClass(body, vl.ns + 'u-no-overflow');
        openBtn.setAttribute('aria-expanded', false);
      };

      document.addEventListener('click', closePopup);
    };

    // Helpers
    /**
     * Get the height of an element.
     * @private
     * @param  {Node} elem The element to get the height of
     * @return {Number}    The element's height in pixels
     */
    function getHeight(elem) {
      return Math.max( elem.scrollHeight, elem.offsetHeight, elem.clientHeight );
    };
    /**
     * Get an element's distance from the top of the Document.
     * @param  {Node} elem The element
     * @return {Number}    Distance from the top in pixels
     */
    function getOffsetTop(elem) {
      var location = 0;
      if (elem.offsetParent) {
        do {
          location += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      } else {
        location = elem.offsetTop;
      }
      location = location - params.offset;
      return location >= 0 ? location : 0;
    };
  };

  vl.scrollspy.dressAll = function() {
    var scrollspies = document.querySelectorAll('.' + ssClass + ':not(.' + ssBoundClass + ')');
    [].forEach.call(scrollspies, function(scrollspy){
      vl.scrollspy.dress(scrollspy);
    });
  };

  // Initiate on load
  window.addEventListener('load', function() {
    vl.scrollspy.dressAll();
  });

})();
