/**
 * Progressively enhance a multiselect field
 */

// create global vl multiselect function
vl.multiselect = {};
vl.multiselect.dress;
vl.multiselect.dressAll;
vl.multiselect.setDisabledState;

(function () {

  var msClass = 'js-' + vl.ns + 'select',
      msBoundClass = 'js-' + vl.ns + 'multiselect-bound',
      msInputClass = msClass + '__input',
      msCtaClass  = vl.ns + 'select__cta',
      msCtaTitleClass  = vl.ns + 'select__cta__title',
      msGroupClass  = vl.ns + 'select__group',
      msListClass  = vl.ns + 'select__list',
      msListWrapperClass  = vl.ns + 'select__list-wrapper',
      msWrapperClass  = vl.ns + 'select__wrapper',
      msFormClass  = vl.ns + 'select__form',
      msCtaActiveClass  = msCtaClass + '--active',
      lastSelectId, lastContainer,
      dataPrefix = 'data-' + vl.ns,
      msContentAtt    = dataPrefix + 'content',
      msInputAtt    = dataPrefix + 'input',
      msRecordsAtt    = dataPrefix + 'records',
      msRecordAtt    = dataPrefix + 'record',
      msFocusAtt    = dataPrefix + 'focus',
      msShowAtt    = dataPrefix + 'show',
      msSelectedAtt    = dataPrefix + 'selected',
      msIndexAtt    = dataPrefix + 'index',
      msLabelAtt    = dataPrefix + 'label',
      msAtt    = dataPrefix + 'multiselect',
      msIDAtt    = dataPrefix + 'id',
      msPlaceholderAtt    = dataPrefix + 'placeholder',
      msValueAtt    = dataPrefix + 'value',
      msEmptyAtt    = dataPrefix + 'empty',
      msSearchEmptyAtt    = dataPrefix + 'search-empty';

  vl.multiselect.dress = function(selectField) {
    addClass(selectField, msBoundClass);

    /*
    * Variables needed in Generate selects basted on original <select> elements
    */
    var arr                       = generateSelect(selectField),
        arrOptions                = arr[0],
        selectId                  = arr[1],
        selectContainer           = arr[2],
        activeArrOptions          = arrOptions, // = options that are shown
        selectDummyInput          = selectContainer.querySelector('.' + msInputClass),
        selectContent             = selectContainer.querySelector('['+msContentAtt+']'),
        selectContentInput        = selectContent.querySelector('['+msInputAtt+']'),
        selectContentList         = selectContent.querySelector('['+msRecordsAtt+']'),
        selectContentListItems    = selectContent.querySelectorAll('['+msRecordAtt+']'),
        selectFocusElems          = selectContainer.querySelectorAll('['+msFocusAtt+']'),
        selectedArrOptions        = generatePills(selectField, selectContainer, selectDummyInput, selectContentListItems, selectId); // = options that are selected
    /*
    * Events in select element
    */
    (selectContentInput ? selectContentInput.addEventListener('keyup', selectContentInputKeyUpHandler) : null );
    (selectContentInput ? selectContentInput.addEventListener('keydown', selectContentInputKeyDownHandler) : null );
    (selectContainer ? selectContainer.addEventListener('keydown', selectContainerKeyDownEventHandler) : null );
    (selectDummyInput ? selectDummyInput.addEventListener('click', selectDummyInputClickEventHandler) : null );

    document.addEventListener('click', function(e){
      setSelectState(false);
    });

    selectContainer.addEventListener('click', function(e){
      e.stopPropagation();
      e.preventDefault();
    });

    /*
    * Eventhandler | selectContainer keyDown
    */
    function selectContentInputKeyDownHandler(e){
      if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        return false;
      }

      switch(e.keyCode){
        case 27: case 9:
          e.preventDefault();
          return false;
        break;
      }
    };

    /*
    * Eventhandler | selectContainer keyUp
    */
    function selectContentInputKeyUpHandler(e){
      e.preventDefault();
      if(e.shiftKey && e.keyCode == 9){ // Tab + Shift
        setSelectState(false);
        selectDummyInput.focus();
      }
      var curOption, curOptionIndex;
      (activeArrOptions.length <= 0 ? resetOptions() : setCurrentOption() );
      switch(e.keyCode){
        case 9: // "Tab key"
          fireEvent(document, 'click');
          selectDummyInput.focus();
        break;
        case 27: // "Esc key"
          fireEvent(document, 'click');
          selectDummyInput.focus();
        break;
        case 8: // "Backspace key"
          resetOptions();
          _keyDefaultHandler();
        break;
        case 32: // "Space key"
          (selectContent.getAttribute(msShowAtt) !== "true" ? selectDummyInput.click() : null);
        break;
        case 13: // "Enter key"
        if(selectContent.getAttribute(msShowAtt) !== "true"){
          setSelectState(true);
          (selectContentInput !== null ? selectContentInput.focus() : selectDummyInput.focus());
        }else{
          curOption.click();
        }
        break;
        case 38: // "Arrow up key"
          (activeArrOptions.length > 0 ? _keyUpHandler() : null );
        break;
        case 40: // "Arrow down key"
          (activeArrOptions.length > 0 ? _keyDownHandler() : null);
        break;
        default:
          _keyDefaultHandler();
        break;
      }

      function _keyUpHandler(){
        e.preventDefault();

        if(selectContent.getAttribute(msShowAtt) !== "true"){
          // Tonen bij arrow down en index één verhogen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex++;
        }

        if(curOptionIndex > 0){
          curOptionIndex--;
          curOption.removeAttribute(msSelectedAtt);
          var el = selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="'+curOptionIndex+'"]');
          el.setAttribute(msSelectedAtt,'true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function _keyDownHandler(){
        e.preventDefault();

        if(selectContent.getAttribute(msShowAtt) !== "true"){
          // Tonen bij arrow down en index één minderen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex--;
        }

        if(curOptionIndex < (activeArrOptions.length - 1)){
          curOptionIndex++;
          curOption.removeAttribute(msSelectedAtt);
          var el = selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="'+curOptionIndex+'"]');
          el.setAttribute(msSelectedAtt,'true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function setCurrentOption(){
        curOption = selectContentList.querySelector('['+msRecordAtt+']['+msSelectedAtt+'="true"]');
        (curOption == null ? curOption = selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]') : null);
        curOptionIndex = curOption.getAttribute(msIndexAtt);
      }

      function resetOptions(){
        for(var item, i = 0; item = arrOptions[i]; i++) {
          var el = selectContentList.querySelector('['+msRecordAtt+']['+msLabelAtt+'="'+item+'"]');
              el.removeAttribute(msSelectedAtt);
              el.setAttribute(msShowAtt, 'true');
              el.setAttribute(msIndexAtt, i);
        }

        if(selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]') !== null)
          selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]').setAttribute(msSelectedAtt,'true');

        activeArrOptions = arrOptions;
      }
    }

    function _keyDefaultHandler(selectedEl){
      selectedEl = selectedEl || null;
      if(selectContentInput !== null){
        var val = selectContentInput.value, first; activeArrOptions = [];

        for(var item, i = 0; item = arrOptions[i]; i++) {
          var el = selectContentList.querySelector('['+msRecordAtt+']['+msLabelAtt+'="'+item+'"]');

          // Set visibility hidden of all items & Remove old index of all items & Remove old focus
          el.setAttribute(msShowAtt, 'false');
          el.removeAttribute(msIndexAtt);
          el.removeAttribute(msSelectedAtt);

          // If substring is present in string show item and push to array
          if(item.toLowerCase().indexOf(val.toLowerCase() ) > -1){
            el.setAttribute(msShowAtt, 'true');
            activeArrOptions.push(item);
          }
        }

        if(activeArrOptions.length > 0){
          setNoResultsFoundElement("hide", selectContainer, selectContentList);
          for(var opt, i = 0; opt = activeArrOptions[i]; i++) {
            selectContentList.querySelector('['+msRecordAtt+']['+msLabelAtt+'="'+opt+'"]').setAttribute(msIndexAtt, i);
          }
          // Set focus on first element
          if(selectedEl !== null) {
            selectedEl.setAttribute(msSelectedAtt,'true');
            window.setTimeout(function(){
              selectedEl.focus();
              selectContentInput.focus();
            }, 1);
          } else {
            var el = selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]');
            (el !== null ? el.setAttribute(msSelectedAtt,'true') : null );
          }
        }else{
          setNoResultsFoundElement("show", selectContainer, selectContentList);
        }
        var optgroups = selectContentList.querySelectorAll('.' + msGroupClass);
        [].forEach.call(optgroups, function(optgroup){
          var items = optgroup.querySelectorAll('.' + msCtaClass + '['+msShowAtt+'="true"]');
          (!items.length ? optgroup.style.display = "none" : optgroup.style.display = "block" );
        });
      }
    }

    /*
    * Eventhandler | selectContainer keyDown
    */
    function selectContainerKeyDownEventHandler(e){
      switch(e.keyCode){
        case 13: case 38: case 40:
        e.preventDefault();
        break;
      }
    }

    /*
    * Eventhandler | selectDummyInput Click
    */
    function selectDummyInputClickEventHandler(e){
      if(selectContent.getAttribute(msShowAtt) === "false"){
        // Show select
        setSelectState(true);
        // Set focus on search if present
        if(selectContentInput !== null){
          selectContentInput.focus();
        }
        // Select first element in generate records
        if(selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]') !== null)
          selectContentList.querySelector('['+msRecordAtt+']['+msIndexAtt+'="0"]').setAttribute(msSelectedAtt,'true');
      }
      else{
        setSelectState(false);
      }
    }

    /*
    * Loop through dynamically generated records
    */
    [].forEach.call(selectContentListItems, function(item){
      var lbl = item.getAttribute(msLabelAtt);
      var val = item.getAttribute(msValueAtt);

      item.addEventListener('click', function(e){

        // toggle active class to selected element
        toggleClass(item, msCtaActiveClass);

        // Set selected state to original select
        selectedArrOptions = setOriginalSelectFieldOptions(selectId, selectContentListItems);

        // Generate pills
        generatePills(selectField, selectContainer, selectDummyInput, selectContentListItems, selectId);

        // Set focus
        (selectContentInput !== null ? selectContentInput.focus() : selectDummyInput.focus());

        // Remove query in select input
        if(selectContentInput !== null){
          selectContentInput.value = "";
          _keyDefaultHandler(selectContentList.querySelector('['+msSelectedAtt+'="true"]'));
        }

      });
    });

    /*
    * setSelectState()
    * Setting the general data attributes & aria tags of the generated select
    */
    function setSelectState(isShown){
      if(isShown)
        var dataShow = true, ariaHidden = false, ariaExpanded = true;
      else
        var dataShow = false, ariaHidden = true, ariaExpanded = false;

      selectContent.setAttribute(msShowAtt, dataShow);
      selectContent.setAttribute('aria-hidden', ariaHidden);
      selectDummyInput.setAttribute('aria-expanded', ariaExpanded);
    }
  };

  /*
  * Loop through all select fields
  */
  vl.multiselect.dressAll = function() {

    var elements = document.querySelectorAll('['+msAtt+']:not(.' + msBoundClass + ')');

    [].forEach.call(elements, function(element) {
      vl.multiselect.dress(element);
    });
  };

  // Initiate
  vl.multiselect.dressAll();

  /*
  * setDisabledState()
  * Sets disabled state of both native and generated select
  * @param selectField(object)
  * @param state(boolean)
  */
  vl.multiselect.setDisabledState = function(selectField, state) {

    var selectContainer   = selectField.closest('.' + msClass);
    var selectDummyInput  = selectContainer.querySelector('.' + msInputClass);

    if(state){
      selectField.setAttribute('disabled', state);
      selectDummyInput.setAttribute('disabled', state);
    }else{
      selectField.removeAttribute('disabled');
      selectDummyInput.removeAttribute('disabled');
    }
  };

  /*
  * setVisibilityAttributes()
  * Setting the general data attributes & aria tags
  */
  function setVisibilityAttributes(field, dataShow, ariaHidden){
      field.setAttribute(msShowAtt,   dataShow);
      field.setAttribute('aria-hidden', ariaHidden);
  }

  /*
  * generateSelect()
  * Generating the ehanced select
  */
  function generateSelect(selectField){
    // Hide normal select field
    addClass(selectField, vl.ns + 'u-visually-hidden');
    selectField.setAttribute('aria-hidden','true');
    selectField.setAttribute('tabindex','-1');

    var arr = [], uniqId = uniqueId();

    // Set selectContainer
    var selectContainer = selectField.closest('.' + msClass);

    // Get data-id or generate one
    if(selectField.hasAttribute(msIDAtt)){
      uniqId = selectField.getAttribute(msIDAtt);
      selectContainer.setAttribute(msIDAtt, uniqId);
    }else{
      selectContainer.setAttribute(msIDAtt, uniqId);
    }


    // Fake select field
    var selectDummyButton = document.createElement("button"),
        selectDummyInput = document.createElement("div");

      selectDummyInput.setAttribute(msFocusAtt, '');
      selectDummyInput.setAttribute('id', uniqId);
      addClass(selectDummyInput, msInputClass);
      addClass(selectDummyInput, msInputClass + '--multi');

      selectDummyButton.setAttribute('aria-haspopup', 'true');
      selectDummyButton.setAttribute('aria-expanded', 'false');
      addClass(selectDummyButton, msInputClass + '__button');

      if (selectField.hasAttribute('disabled') && selectField.getAttribute('disabled') !== "false"){
        selectDummyInput.setAttribute('disabled','true');
      }

      var placeholder = selectField.querySelector('['+msPlaceholderAtt+']');
      selectDummyButton.innerHTML = placeholder.label;

      selectContainer.appendChild(selectDummyInput);
      selectContainer.appendChild(selectDummyButton);


    // Select Wrapper
    var selectWrapper = document.createElement("div");
        addClass(selectWrapper, msWrapperClass);
        selectWrapper.setAttribute(msContentAtt,'');
        selectWrapper.setAttribute('aria-labelledby',uniqId);
        setVisibilityAttributes(selectWrapper, false, true);

        selectContainer.appendChild(selectWrapper);

        // Select Form Wrapper
        var selectForm = document.createElement("div");
            addClass(selectForm, msFormClass);

            selectWrapper.appendChild(selectForm);

            // Select Form Input
            var selectFormInput = document.createElement('input');
                selectFormInput.setAttribute('type','text');
                selectFormInput.setAttribute('autocomplete','off');
                addClass(selectFormInput, vl.ns + 'input-field');
                addClass(selectFormInput, vl.ns + 'input-field--block');
                selectFormInput.setAttribute(msInputAtt,'');
                selectFormInput.setAttribute(msFocusAtt, '');
                selectFormInput.setAttribute('value','');
                selectFormInput.setAttribute('tabindex','-1');
                selectFormInput.setAttribute('aria-describedby', vl.ns + 'selectFormInputDescription' + uniqId);
                selectFormInput.setAttribute('aria-haspopup', 'listbox"');

                selectForm.appendChild(selectFormInput);

                var selectFormInputDescription = document.createElement('span');
                    selectFormInputDescription.setAttribute('id', vl.ns + 'selectFormInputDescription' + uniqId);
                    selectFormInputDescription.innerHTML = "U bevindt zich in de zoekfunctie van een dropdown menu met multiselect in een formulier. Navigeer door de opties met de pijltjes en selecteer met enter. Gebruik escape om de dropdown te sluiten.";
                    addClass(selectFormInputDescription, vl.ns + 'u-visually-hidden');

                    selectForm.appendChild(selectFormInputDescription);

        // Select List Wrapper
        var selectListWappper = document.createElement('div');
                addClass(selectListWappper,msListWrapperClass);
                selectListWappper.setAttribute('role','listbox');

                selectWrapper.appendChild(selectListWappper);

                // Select List
                var selectList = document.createElement('section');
                    addClass(selectList, msListClass);
                    selectList.setAttribute(msRecordsAtt,'');

                    selectListWappper.appendChild(selectList);

                    // Generate option groups based on optgroups in real select
                    var optgroups = selectField.querySelectorAll('optgroup');
                    if(optgroups.length > 0){
                      [].forEach.call(optgroups, function(optgroup){
                        var label = optgroup.getAttribute('label');
                        var selectOptionGroupWrapper = document.createElement('section');
                        addClass(selectOptionGroupWrapper, msGroupClass);
                        selectOptionGroupWrapper.setAttribute(msLabelAtt, label);
                        selectOptionGroupWrapper.setAttribute('role', 'group');
                        selectList.appendChild(selectOptionGroupWrapper);

                        var selectOptionGroupTitle = document.createElement('h1');
                        selectOptionGroupTitle.innerHTML = label;

                        selectOptionGroupWrapper.appendChild(selectOptionGroupTitle);
                      });
                    }

                    // Generate list items based on options in real select
                    var i = 0;
                    [].forEach.call(selectField.options, function(opt){
                      var value = opt.value;
                      var label = opt.innerHTML;

                      // If item has "data-placeholder" it's used as a placeholder item
                      if(!opt.hasAttribute(msPlaceholderAtt)){
                        // SelectOption
                        var selectOption = document.createElement('div');
                        addClass(selectOption, vl.ns + 'select__item');

                        // Titel (button wrapper)
                        var selectOptionButton = document.createElement('button');
                            addClass(selectOptionButton, msCtaClass);
                            // If option is selected set the element active and change the label in the DummyInput
                            if(opt.selected){
                              addClass(selectOptionButton, msCtaActiveClass);
                              selectOptionButton.setAttribute('aria-selected', true);
                            }else{
                              selectOptionButton.setAttribute('aria-selected', false);
                            }

                            selectOptionButton.setAttribute('type', 'button');
                            selectOptionButton.setAttribute(msIndexAtt, i);
                            selectOptionButton.setAttribute(msValueAtt, value);
                            selectOptionButton.setAttribute(msLabelAtt, label);
                            selectOptionButton.setAttribute(msRecordAtt,'');
                            selectOptionButton.setAttribute(msFocusAtt, '');
                            selectOptionButton.setAttribute('role', 'option');
                            selectOptionButton.setAttribute('tabindex','-1');

                            // Titel (span wrapper)
                            var selectOptionTitleSpan = document.createElement("span");
                                addClass(selectOptionTitleSpan, msCtaTitleClass);
                                selectOptionTitleSpan.innerHTML = label;

                                // Appends
                                selectOptionButton.appendChild(selectOptionTitleSpan);
                            selectOption.appendChild(selectOptionButton);

                        // Assign to option group if available
                        var closestOptGroup = opt.closest('optgroup');
                        if(closestOptGroup !== null){
                          var closestGeneratedOptGroup = selectList.querySelector('['+msLabelAtt+'="' + closestOptGroup.getAttribute('label') + '"]')
                          closestGeneratedOptGroup.appendChild(selectOption);
                        }else{
                          selectList.appendChild(selectOption);
                        }

                        // Add to arrOptions array
                        arr.push(label);
                        i++;
                      }
                    });

      return [arr, uniqId, selectContainer];
  }


  /*
  * generatePill()
  * Generating pills used in the multiselect input field
  */
  function generatePills(selectField, selectContainer, selectDummyInput, selectContentListItems, selectId){

    var activeOpts = selectContainer.getElementsByClassName(msCtaActiveClass);
    var selectContentInput = selectContainer.querySelector('['+msInputAtt+']');

    // Clear all elements
    selectDummyInput.innerHTML = "";

    // If present: generate pills based on active options
    if(activeOpts.length > 0){
      [].forEach.call(activeOpts, function(item){
          var pillWrapper = document.createElement("div");
          addClass(pillWrapper, vl.ns + 'pill'); addClass(pillWrapper, vl.ns + 'pill--closable');
          selectDummyInput.appendChild(pillWrapper);

            var pillSpan = document.createElement("span");
            pillSpan.innerHTML = item.getAttribute(msLabelAtt);
            pillWrapper.appendChild(pillSpan);

            var pillCta = document.createElement("a");
            addClass(pillCta, vl.ns + 'pill__close');
            pillCta.setAttribute('href', '#');
            pillCta.setAttribute(msValueAtt, item.getAttribute(msValueAtt));
            pillCta.innerHTML = "verwijder optie";

            // Remove pill on click/keyup(enter)
            pillCta.addEventListener('click', ctaEvent);
            pillCta.addEventListener('keyup', ctaKeydownEvent);

            function ctaKeydownEvent(e){
              if(e.keyCode === 13){
                ctaEvent(e);
              }
            }
            function ctaEvent(e){
              e.preventDefault();

              // Remove active class from corresponding item in select
              var correspondingItem = selectContainer.querySelector('['+msRecordAtt+']['+msValueAtt+'="'+ item.getAttribute(msValueAtt) +'"]');
              removeClass(correspondingItem, msCtaActiveClass);

              // Generate pills
              generatePills(selectField, selectContainer, selectDummyInput, selectContentListItems, selectId);

              // Set selectfield options
              selectedArrOptions = setOriginalSelectFieldOptions(selectId, selectContentListItems);

              if(e.stopPropagation)
                e.stopPropagation();
              else
                e.cancelBubble = true;
            }
            pillWrapper.appendChild(pillCta);
      });
    }else{
      // Set placeholder or empty field
      var placeholder = selectField.querySelector('['+msPlaceholderAtt+']');
      if(placeholder !== null){
        selectDummyInput.innerHTML = placeholder.label;
      }else{
        selectDummyInput.innerHTML = "";
      }
    }
    return activeOpts;
  }

  /*
  * setNoResultsFoundElement()
  * Generate the "no results found" option
  */
  function setNoResultsFoundElement(state, selectContainer, selectContentList){
    switch(state){
      case "show":
        var prevEl = selectContentList.querySelector('['+msEmptyAtt+']');
        if(prevEl == null){
          var noResultsFoundElement = document.createElement('div');
              addClass(noResultsFoundElement, vl.ns+"select__item");

              selectContentList.appendChild(noResultsFoundElement);

              var noResultsFoundElementContent = document.createElement('div');
                  addClass(noResultsFoundElementContent, vl.ns+'select__empty');
                  noResultsFoundElementContent.setAttribute(msEmptyAtt, '');
                  if(selectContainer.hasAttribute(msSearchEmptyAtt)){
                    noResultsFoundElementContent.innerHTML = selectContainer.getAttribute(msSearchEmptyAtt);
                  }else{
                    noResultsFoundElementContent.innerHTML = "No results found";
                  }

                  noResultsFoundElement.appendChild(noResultsFoundElementContent);
        }
      break;

      case "hide":
        var prevEl = selectContentList.querySelector('['+msEmptyAtt+']');
        if(prevEl !== null){
          removeElement(prevEl);
        }
      break;
    }
  }

  /*
  * setOriginalSelectFieldOptions()
  * Setting the options in the hidden select field equal to the element selected in the generated select
  */
  function setOriginalSelectFieldOptions(selectId, selectContentListItems){

    var sel = document.querySelector('.' + msClass + '['+msIDAtt+'="'+selectId+'"] select');
    var selectedArrOptions = [];
    var values = [];

    [].forEach.call(selectContentListItems, function(item){
      if(hasClass(item, msCtaActiveClass)){
        selectedArrOptions.push(item);
        values.push(item.getAttribute(msValueAtt));
      }
    });

      var opts = sel.options;
      for (var i = 0; i < opts.length; i++)
      {
          opts[i].selected = false;
          for (var j = 0; j < values.length; j++)
          {
              if (opts[i].value == values[j])
              {
                  opts[i].selected = true;
                  break;
              }
          }
      }

      // Return selected all options
      return selectedArrOptions;
  }

})();
