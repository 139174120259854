/**
 * Dress the sticky elements. Mostly used for side navigation
 * An element is sticky in its closest parent .layout
 * If layout is not present, which should be, we take .region
 */

vl.sticky = {};
vl.sticky.dress;
vl.sticky.dressAll;

(function () {

  var stiClass            = 'js-' + vl.ns + 'sticky',
      stiBoundClass       = 'js-' + vl.ns + 'sticky-bound',
      stiAbsoluteClass    = stiClass + '--absolute',
      stiFixedClass       = stiClass + '--fixed',
      stiPlaceholderClass = stiClass + '--placeholder',
      regionClass         = vl.ns  + 'region',
      layoutClass         = vl.ns  + 'layout',
      latestKnownScrollY  = 0,
      ticking             = false,
      resized             = true,
      stickyTopAtt        = 'data-' + vl.ns + 'original-top',
      stickyLeftAtt        = 'data-' + vl.ns + 'original-left',
      stickyWidthAtt        = 'data-' + vl.ns + 'original-width',
      stickyMaxYAtt        = 'data-' + vl.ns + 'max-y',
      stickyOffsetAtt        = 'data-' + vl.ns + 'sticky-offset-top';

  // prepare the elements by wrapping in placeholder
  vl.sticky.dress = function (stickyContent) {
    addClass(stickyContent, stiBoundClass);

    // set the original position on load + add parent if required
    storeElementData(stickyContent);

    // put placeholder around the sticky content
    var placeholder = document.createElement('div');
    addClass(placeholder, stiPlaceholderClass);
    wrap(stickyContent, placeholder);
    // set placeholder height fixed
    placeholder.style.height = placeholder.offsetHeight + "px";
  }

  function storeElementData(stickyContent) {
    removeClass(stickyContent, stiFixedClass);
    removeClass(stickyContent, stiAbsoluteClass);

    // set position properties
    stickyContent.style.top = 'auto';
    stickyContent.style.bottom = 'auto';
    stickyContent.style.left = 'auto';
    stickyContent.style.width = 'auto';

    var rect = stickyContent.getBoundingClientRect();
    var offsetTop = rect.top + window.pageYOffset;
    stickyContent.setAttribute(stickyTopAtt, offsetTop);
    var offsetLeft = rect.left + window.pageXOffset;
    stickyContent.setAttribute(stickyLeftAtt, offsetLeft);
    stickyContent.setAttribute(stickyWidthAtt, rect.width);
    var stickyContainer = stickyContent.closest('.' + layoutClass, '.' + regionClass);
    stickyContent.setAttribute(stickyMaxYAtt, stickyContainer.getBoundingClientRect().bottom + window.pageYOffset);
  }

  function makeSticky(element, offset, scrollPosition) {
    var placeholder = element.parentElement;

    // if the user has scrolled passed the start + offset of the element, make it sticky or update its sticky position
    if(scrollPosition + offset >= element.getAttribute(stickyTopAtt)) {
      var elementHeight = element.offsetHeight;
      // If the element bottom is going outside of the container, make it absolute
      if(element.getAttribute(stickyMaxYAtt) > elementHeight && element.getAttribute(stickyMaxYAtt) - scrollPosition - offset < elementHeight) {
        addClass(element, stiAbsoluteClass);
        removeClass(element, stiFixedClass);

          // set position properties
          element.style.top = (element.getAttribute(stickyMaxYAtt) - elementHeight - element.getAttribute(stickyTopAtt)) + 'px';
          element.style.bottom = 'auto'; // calc position t.o.v. placeholder
          element.style.left = 'auto';
          element.style.width = '100%';

      } else {
        addClass(element, stiFixedClass);
        removeClass(element, stiAbsoluteClass);
        // set position properties
        element.style.top = offset + 'px';
        element.style.bottom = 'auto';
        element.style.left = element.getAttribute(stickyLeftAtt) + 'px';
        element.style.width = element.getAttribute(stickyWidthAtt) + 'px';
      }

    } else if(hasClass(element, stiAbsoluteClass) || hasClass(element, stiFixedClass)) {
      removeClass(element, stiFixedClass);
      removeClass(element, stiAbsoluteClass);

      // set position properties
      element.style.top = 'auto';
      element.style.bottom = 'auto';
      element.style.left = 'auto';
      element.style.width = 'auto';
    }
  }

  var refresh = function(){
    if(resized) {
      requestAnimationFrame(resetElements);
    }
    resized = false;
  };

  function resetElements () {
    resized = true;

    var elements = document.querySelectorAll('.' + stiClass + '.' + stiBoundClass);
    [].forEach.call(elements, function(el) {
      storeElementData(el);
      onScroll();
    });
  }

  function onScroll() {
    latestKnownScrollY = window.pageYOffset;
    requestTick();
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }


  function update() {
    ticking = false;
    var currentScrollY = latestKnownScrollY;
    var offset = 75;

    var elements = document.querySelectorAll('.' + stiClass + '.' + stiBoundClass);
    [].forEach.call(elements, function(el) {
      // Default offset
      if(el.hasAttribute(stickyOffsetAtt) && isNumeric(el.getAttribute(stickyOffsetAtt)))
        offset = parseInt(el.getAttribute(stickyOffsetAtt));

      makeSticky(el, offset, currentScrollY);
    });
  }

  // only add sticky if all content is loaded
  vl.sticky.dressAll = function () {

    if(vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall'){
      return;
    }

    var elements = document.querySelectorAll('.' + stiClass + ':not(.' + stiBoundClass + ')');
    [].forEach.call(elements, function (stickyContent) {
      vl.sticky.dress(stickyContent);
    });

    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', refresh, false);

    onScroll();
  };

  // Initiate
  window.addEventListener('load', function() {
    vl.sticky.dressAll();
  });

})();

// @todo: optimize
// - detect content changes
