/**
 * Simulate float-right functionality for a col element
 */

vl.grid = {};
vl.grid.colFloatRight = {};
vl.grid.colFloatRight.dress;
vl.grid.colFloatRight.dressAll;

(function () {

  if(!Modernizr.flexbox){
    return;
  }

  var cfrClass            = 'js-' + vl.ns + 'col-float-right',
      cfrBoundClass       = 'js-' + vl.ns + 'col-float-right-bound',
      cfrPushedRightClass = 'js-' + vl.ns + 'col-float-right--pushed';

  /**
   * dress
   * @param  {[DOM element]} col js-col-float-right element
   */
  vl.grid.colFloatRight.dress = function (col) {
    addClass(col, cfrBoundClass);
    var colRect = col.getBoundingClientRect();
    // the intersecting col elements
    // get all col elements
    var cols = document.querySelectorAll('.grid > *');
    [].forEach.call(cols, function (element) {
      // check if the element is not inside a floating col
      var parent = element.closest(cfrClass);
      var rect = element.getBoundingClientRect();
      if(element !== col && parent == null && rect.right > colRect.left && rect.top < colRect.bottom && rect.bottom > colRect.top){
        // add margin to the parent
        var grid = element.closest('.grid');
        var margin = colRect.bottom - rect.top;
        grid.style.marginTop = parseInt(margin)+'px';
        addClass(grid, cfrPushedRightClass);
      }
    });
  };

  if(vl.breakpoint.value !== 'small' && vl.breakpoint.value !== 'xsmall') {
    var elements = document.querySelectorAll('.' + cfrClass + ':not(.' + cfrBoundClass + ')');
    [].forEach.call(elements, function (element) {
      vl.grid.colFloatRight.dress(element);
    });
  }

  /**
   * dressAll
   */
  vl.grid.colFloatRight.dressAll = function () {
    var elements = document.querySelectorAll('.' + cfrClass + ':not(.' + cfrBoundClass + ')');
    if(vl.breakpoint.value !== 'small' && vl.breakpoint.value !== 'xsmall') {
      [].forEach.call(elements, function (element) {
        vl.grid.colFloatRight.dress(element);
      });
    }
  };

  /**
   * resize Event
   */
  window.addEventListener('resize', function() {
    vl.grid.colFloatRight.dressAll();
  });

})();
