/**
 * Double Input
**/

vl.doubleinput = {};
vl.doubleinput.dress;
vl.doubleinput.dressAll;

(function(){
  var diClass               = 'js-' + vl.ns + 'double-input',
      diBoundClass          = diClass + '-bound',
      diTopLabelActiveClass = diClass + '-top-label--active',
      dataPrefix            = 'data-' + vl.ns + 'double-input',
      diLabelHighlightAtt   = dataPrefix + '-label-highlight',
      diLabelAtt            = dataPrefix + '-label';

  /**
   * vl.doubleinput.dress
   * @param  {DOM element} element ".js-double-input" DOM element
   */
  vl.doubleinput.dress = function(element) {
    addClass(element, diBoundClass);

    var activeClass = diTopLabelActiveClass;
    // add focus event to the input fields
    var inputs = element.querySelectorAll('['+diLabelHighlightAtt+']');
    // add events
    [].forEach.call(inputs, function(input) {
      input.addEventListener('focus', function() {
        var labelID = input.getAttribute(diLabelHighlightAtt);
        var label = element.querySelector('['+diLabelAtt+'="'+labelID+'"]');
        addClass(label, activeClass);
      });
      input.addEventListener('blur', function() {
        var labelID = input.getAttribute(diLabelHighlightAtt);
        var label = element.querySelector('['+diLabelAtt+'="'+labelID+'"]');
        removeClass(label, activeClass);
      });
    });
  };

  /**
   * vl.doubleinput.dressAll - Initiates all double-inputs in the DOM
   */
  vl.doubleinput.dressAll = function() {
    // get all elements with a data-attribute data-clamp
      var elements = document.querySelectorAll('.' + diClass + ':not(.' + diBoundClass + ')');
      // apply highlighting for all fields
      [].forEach.call(elements, function(element) {
        vl.doubleinput.dress(element);
      });
  };

  // Initiate
  vl.doubleinput.dressAll();

})();
