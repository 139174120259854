(function(factory){

  window.Tooltip = factory();

})(function(){

  var ttClass         = vl.ns + 'tooltip',
      ttCloseClass    = vl.ns + 'close',
      ttArrowclass    = ttClass + '__arrow',
      ttTitleClass    = ttClass + '__title',
      ttCloseClass2   = ttClass + '__close',
      ttLargeClass    = ttClass + '--large',
      ttContentClass  = ttClass + '__content',
      ttTitleAtt      = 'data-' + vl.ns + 'title',
      ttContentAtt    = 'data-' + vl.ns + 'content',
      ttTimerAtt      = 'data-' + vl.ns + 'timer',
      ttToggleAtt     = 'data-' + vl.ns + 'toggle',
      ttTriggerAtt    = 'data-' + vl.ns + 'trigger',
      ttDurationAtt   = 'data-' + vl.ns + 'duration',
      ttPlacementAtt  = 'data-' + vl.ns + 'placement',
      ttDismissAtt    = 'data-' + vl.ns + 'dismiss',
      ttShowAtt       = 'data-' + vl.ns + 'show';


  // Tooltip
  var Tooltip = function( element,options ) {
    options = options || {};
    this.isIE = (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null) ? parseFloat( RegExp.$1 ) : false;
    this.link = typeof element === 'object' ? element : document.querySelector(element);
    this.title = this.link.getAttribute(ttTitleAtt) || null;
    this.content = this.link.getAttribute(ttContentAtt) || null;
    this.tooltip = null;
    this.options = {};
    this.options.trigger = options.trigger ? options.trigger : 'hover';
    this.options.placement = options.placement ? options.placement : 'top';
    this.options.dismiss = options.dismiss && options.dismiss === 'true' ? true : false;
    //Custom
    this.options.show = options.show ? options.show : false;

    this.duration = 150;
    this.options.duration = (this.isIE && this.isIE < 10) ? 0 : (options.duration || this.duration);
    this.options.container = document.body;
    if ( this.content ) this.init();
    this.timer = 0 // the link own event timer
    this.rect = null;
  }

  // Methods
  Tooltip.prototype = {

    init : function() {

      this.actions();
      if (this.options.trigger === 'hover') {
        this.link.addEventListener('mouseenter', this.open, false);
        if (!this.options.dismiss) { this.link.addEventListener('mouseleave', this.close, false); }
      } else if (this.options.trigger === 'click') {
        this.link.addEventListener('click', this.toggle, false);
        if (!this.options.dismiss) { this.link.addEventListener('blur', this.close, false); }
      } else if (this.options.trigger === 'focus') {
        this.link.addEventListener('focus', this.toggle, false);
        if (!this.options.dismiss) { this.link.addEventListener('blur', this.close, false); }
      }

      // If data-show = true show the the toggle directly
      if(this.options.show) { this.open(); };

      // Custom extras
      // By default show on focus
      this.link.addEventListener('focus', this.toggle, false);
      // When out of focus close popup again
      this.link.addEventListener('blur', this.close, false);

      if (this.options.dismiss) { document.addEventListener('click', this.dismiss, false); }

      if (!(this.isIE && this.isIE < 9) && (this.options.trigger === 'focus' || this.options.trigger === 'click') ) {
        window.addEventListener('resize', this.close, false ); } // dismiss on window resize
    },

    actions : function() {
      var self = this;

      this.toggle = function(e) {
        if (self.tooltip === null) {
          self.open()
        } else {
          self.close()
        }
      },
      this.open = function(e) {
        clearTimeout(self.link.getAttribute(ttTimerAtt));
        self.timer = setTimeout( function() {
          if (self.tooltip === null) {
            self.createTooltip();
            self.styleTooltip();
            self.updateTooltip()
          }
        }, self.options.duration );
        self.link.setAttribute(ttTimerAtt,self.timer);
      },
      this.dismiss = function(e) {
        if (self.tooltip && e.target === self.tooltip.querySelector('.' + ttCloseClass)) {
          self.close();
        }
      },
      this.close = function(e) {
        clearTimeout(self.link.getAttribute(ttTimerAtt));
        self.timer = setTimeout( function() {
          if (self.tooltip && self.tooltip !== null && /in/.test(self.tooltip.className)) {
            self.tooltip.className = self.tooltip.className.replace(' in','');
            setTimeout(function() {
              self.removeTooltip(); // for performance/testing reasons we can keep the tooltips if we want
            }, self.options.duration);
          }

        }, self.options.delay + self.options.duration);
        self.link.setAttribute(ttTimerAtt,self.timer);
      },

      // remove the tooltip
      this.removeTooltip = function() {
        this.tooltip && this.options.container.removeChild(this.tooltip);
        this.tooltip = null;
        this.timer = null
      },

      this.createTooltip = function() {
        this.tooltip = document.createElement('div');

        if ( this.content !== null ) { //create the tooltip from data attributes

          this.tooltip.setAttribute('role','tooltip');

          var tooltipArrow = document.createElement('div');
          tooltipArrow.setAttribute('class', ttArrowclass);

          if (this.title !== null) {
            var tooltipTitle = document.createElement('h3');
            tooltipTitle.setAttribute('class', ttTitleClass);

            if (this.options.dismiss) {
              tooltipTitle.innerHTML = this.title + '<button type="button" class="'+ ttCloseClass2 +'">×</button>';
            } else {
              tooltipTitle.innerHTML = this.title;
            }
            this.tooltip.appendChild(tooltipTitle);
          }

          var tooltipContent = document.createElement('div');
          tooltipContent.setAttribute('class', ttContentClass);

          this.tooltip.appendChild(tooltipArrow);
          this.tooltip.appendChild(tooltipContent);

          //set tooltip content
          if (this.options.dismiss && this.title === null) {
            tooltipContent.innerHTML = this.content + '<button type="button" class="'+ ttCloseClass2 +'">×</button>';
          } else {
            tooltipContent.innerHTML = this.content;
          }
        }

        //append to the container
        this.options.container.appendChild(this.tooltip);
        this.tooltip.style.display = 'block';



      },

      this.styleTooltip = function(pos) {
        this.rect = this.getRect();
        var placement = pos || this.options.placement;
        var animation = 'fade';
        var size = '';

        //Create extra "size" item
        if(this.content.length > 80){
          size = ttLargeClass;
        }
        this.tooltip.setAttribute('class', ttClass + ' ' + ttClass + '--'+placement+' '+animation+ ' '+size);

        var linkDim = { w: this.link.offsetWidth, h: this.link.offsetHeight }; //link real dimensions

        // all tooltip dimensions
        var pd = this.tooltipDimensions(this.tooltip);
        var toolDim = { w : pd.w, h: pd.h }; //tooltip real dimensions


        //window vertical and horizontal scroll

        var scrollYOffset = this.getScroll().y;
        var scrollXOffset =  this.getScroll().x;

        //apply styling
        if ( /top/.test(placement) ) { //TOP
          this.tooltip.style.top = this.rect.top + scrollYOffset - toolDim.h + 'px';
          this.tooltip.style.left = this.rect.left + scrollXOffset - toolDim.w/2 + linkDim.w/2 + 'px'

        } else if ( /bottom/.test(placement) ) { //BOTTOM
          this.tooltip.style.top = this.rect.top + scrollYOffset + linkDim.h + 'px';
          this.tooltip.style.left = this.rect.left + scrollXOffset - toolDim.w/2 + linkDim.w/2 + 'px';

        } else if ( /left/.test(placement) ) { //LEFT
          this.tooltip.style.top = this.rect.top + scrollYOffset - toolDim.h/2 + linkDim.h/2 + 'px';
          this.tooltip.style.left = this.rect.left + scrollXOffset - toolDim.w + 'px';

        } else if ( /right/.test(placement) ) { //RIGHT
          this.tooltip.style.top = this.rect.top + scrollYOffset - toolDim.h/2 + linkDim.h/2 + 'px';
          this.tooltip.style.left = this.rect.left + scrollXOffset + linkDim.w + 'px';
        }
      },

      this.updateTooltip = function() {
        var placement = null;
        if ( !self.isElementInViewport(self.tooltip) ) {
          placement = self.updatePlacement();
        } else {
          placement = self.options.placement;
        }

        self.styleTooltip(placement);

        self.tooltip.className += ' in';
      },
      this.updatePlacement = function() {
        var pos = this.options.placement;
        if ( /top/.test(pos) ) { //TOP
          return 'bottom';
        } else if ( /bottom/.test(pos) ) { //BOTTOM
          return 'top';
        } else if ( /left/.test(pos) ) { //LEFT
          return 'right';
        } else if ( /right/.test(pos) ) { //RIGHT
          return 'left';
        }
      },
      this.getRect = function() {
        return this.link.getBoundingClientRect()
      },
      this.getScroll = function() {
        return {
          y : window.pageYOffset || document.documentElement.scrollTop,
          x : window.pageXOffset || document.documentElement.scrollLeft
        }
      },
      this.tooltipDimensions  = function(p) {//check tooltip width and height
        return {
          w : p.offsetWidth,
          h : p.offsetHeight
        }
      },
      this.isElementInViewport = function(t) { // check if this.tooltip is in viewport
        var r = t.getBoundingClientRect();
        return (
          r.top >= 0 &&
          r.left >= 0 &&
          r.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          r.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
      }
    }
    }

  // POPOVER DATA API
  // =================
    var Tooltips = document.querySelectorAll('['+ ttToggleAtt +'=tooltip]'), i = 0, ppl = Tooltips.length;

  for (i;i<ppl;i++){
    var item = Tooltips[i], options = {};
    options.trigger = item.getAttribute(ttTriggerAtt); // click / hover / focus
    options.duration = item.getAttribute(ttDurationAtt);
    options.placement = item.getAttribute(ttPlacementAtt);
    options.dismiss = item.getAttribute(ttDismissAtt);
    options.show = item.getAttribute(ttShowAtt);
    new Tooltip(item,options);
  }

  return Tooltip;
});

