vl.infotext = {};
vl.infotext.fitText;
vl.infotext.fitTextAll;

(function(){

  var itValueClass = 'js-' + vl.ns + 'infotext__value',
      itBoundClass = 'js-' + vl.ns + 'infotext-bound';

  /*
   * vl.infotext.fitText(element)
   * Set text-size of infotext
   * @param 1: ".js-infotext__value" DOM element
   */
  vl.infotext.fitText = function(element) {
    addClass(element, itBoundClass);
    // Add dots in large numbers.
    var number = element.innerHTML;
    number = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    element.innerHTML = number;
    textFit(element, {maxFontSize: 50});
  };

  /*
   * vl.infotext.fitTextAll()
   * Set text-size of all infotexts in the DOM
   */
  vl.infotext.fitTextAll = function() {
    // get all infotext elements
    var elements = document.querySelectorAll('.' + itValueClass + ':not(.' + itBoundClass + ')');
    // set the text-size for the value of each infotext
    [].forEach.call(elements, function(element) {
      vl.infotext.fitText(element);
    });
  };

  window.addEventListener('load', function() {
    vl.infotext.fitTextAll();
  });

  window.addEventListener('resize', function(){
    vl.infotext.fitTextAll();
  });
})();
