vl.popover = {};
vl.popover.dress;
vl.popover.dressAll;

(function(){

  var poClass       = 'js-' + vl.ns + 'popover',
      poToggleClass = poClass + '__toggle',
      poBoundClass  = poClass + '-bound',
      poOpenClass   = poClass + '--open';
  /*
   * vl.popover.dress(element, id)
   * Initiates a popover
   * @param 1: ".js-accordion__toggle" DOM element
   * @param 2: "id" unique ID
   */
  vl.popover.dress = function(element, id){
    addClass(element, poBoundClass);
    // add aria tags
    element.setAttribute('aria-expanded', false);
    element.setAttribute('aria-controls', vl.ns + "popover-"+id);
    // get the closest popover parent
    var popover = element.closest('.' + poClass);
    // add accessibility
    popover.setAttribute('id', vl.ns + "popover-"+id);

    element.addEventListener('click', function(e) {
      e.preventDefault();
      if(!hasClass(popover, poOpenClass)) {
        // close other popovers
        fireEvent(document.getElementsByTagName('body')[0], 'click');
        addClass(popover, poOpenClass);
        element.setAttribute('aria-expanded', true);
      }else {
        // toggle class
        removeClass(popover, poOpenClass);
        element.setAttribute('aria-expanded', false);
      }
    });

    popover.addEventListener('click', function(e){
      e.stopPropagation();
    });

    document.addEventListener('click', function(){
      removeClass(popover, poOpenClass);
      element.setAttribute('aria-expanded', false);
    });

    document.addEventListener('keyup', function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = (evt.key == "Escape" || evt.key == "Esc");
      } else {
        isEscape = (evt.keyCode == 27);
      }
      if (isEscape) {
        removeClass(popover, poOpenClass);
        element.setAttribute('aria-expanded', false);
      }
    });
  };

  /*
   * vl.popover.dressAll()
   * Initiates all popovers in the DOM
   */
  vl.popover.dressAll = function(){
      var elements = document.querySelectorAll('.' + poToggleClass + ':not(.' + poBoundClass + ')');
      var id = 0;

      [].forEach.call(elements, function(element) {
        vl.popover.dress(element, id);
        id++;
      });
  };

  // Initiate
  vl.popover.dressAll();
})();
