/**
 * datatable functionalities
 */

vl.datatable = {};
vl.datatable.dress;
vl.datatable.dressAll;

(function () {
  var dtAtt               = 'data-' + vl.ns +'table',
      dataCheckedChkboxes = '['+dtAtt+'-checkbox]:checked',
      dataTableChk        = '['+dtAtt+'-checkbox]',
      dataActions         = '['+dtAtt+'-action]',
      dtBoundClass        = 'js-'+ vl.ns + 'data-table-bound',
      dtSelectableAtt     = dtAtt + '-selectable',
      dtCheckAllAtt       = dtAtt + '-check-all',
      dtShowAtt           = 'data-' + vl.ns +'show';

  vl.datatable.dress = function(table) {
    addClass(table, dtBoundClass);

    var chkboxes                  = table.querySelectorAll(dataTableChk),
        mainChkbox                = table.querySelector('['+dtCheckAllAtt+']'),
        dataRowSelectable         = table.querySelectorAll('['+dtSelectableAtt+']'),
        dataRowSelectableAnchors  = table.querySelectorAll('['+dtSelectableAtt+'] a, ['+dtSelectableAtt+'] button, ['+dtSelectableAtt+'] input, ['+dtSelectableAtt+'] label');

    // Detect change in general checkbox
    if(mainChkbox !== null){
      mainChkbox.addEventListener('change', function(e){
        if(chkboxes !== null){
          [].forEach.call(chkboxes, function(chk){
            chk.checked = mainChkbox.checked;
          });
        }
      });
    }

    // Detect change in normal checkbox
    if(chkboxes !== null){
      [].forEach.call(chkboxes, function(chk){
        chk.addEventListener('change', function(e){
          toggleActions();
          if(!e.target.checked){
            if(mainChkbox !== null)
              mainChkbox.checked = false;
          }
        });
      });
    }

    // Detect clicks on data-table-selectable attribute
    [].forEach.call(dataRowSelectable, function(row){
      row.addEventListener('click', function(e){
        var chk = row.querySelector(dataTableChk);
        if(chk !== null){
          chk.checked = !chk.checked;
          toggleActions();
        }
      });
    });

    // Detect clicks on anchors, buttons, input elements and labels and stop propagation
    [].forEach.call(dataRowSelectableAnchors, function(anchor){
      anchor.addEventListener('click', function(e){
        if(e.stopPropagation)
          e.stopPropagation();
        else
          e.cancelBubble = true;
      });
    });

    /*
    * toggleActions()
    */
    function toggleActions(){
      var actions = table.querySelectorAll(dataActions);
      if(actions !== null){
        var checkedChkboxes = table.querySelectorAll(dataCheckedChkboxes);
        [].forEach.call(actions, function(action){
          if(checkedChkboxes.length){
            setActionsAttributes(action, true, false);
          }else{
            setActionsAttributes(action, false, true);
          }
        });
      }
    }

    /*
    * setActionsAttributes()
    * action, dataShow, ariaHidden
    */
    function setActionsAttributes(action, dataShow, ariaHidden){
      action.setAttribute(dtShowAtt, dataShow);
      action.setAttribute('aria-hidden', ariaHidden);
    }
  };

  vl.datatable.dressAll = function() {

    var tables = document.querySelectorAll('['+dtAtt+']:not(.' + dtBoundClass + ')');

    [].forEach.call(tables, function(table) {
      vl.datatable.dress(table);
    });
  };

  // initiate
  vl.datatable.dressAll();

})();
