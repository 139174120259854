/**
 * accessible video functionalities
 */

vl.videoPlayer = {};
vl.videoPlayer.dress;
vl.videoPlayer.dressAll;

(function(){
    var videoPlayerClass              = 'js-' + vl.ns + 'video-player',
        videoPlayerToggleBoundClass   = 'js-' + vl.ns + 'video-player-bound';
    /*
    * vl.videoPlayer.dress(element)
    * Initiates a videoPlayer
    * @param 1: ".js-vl-videoPlayer" DOM element
    */
    vl.videoPlayer.dress = function(element) {
        var plyrOptions = {};
        addClass(element, videoPlayerToggleBoundClass);
        plyr.setup(document.querySelectorAll('.'+ videoPlayerClass), plyrOptions);
    }


    /*
    * vl.video.dressAll()
    * Initiates all videos in the DOM
    */
    vl.videoPlayer.dressAll = function() {
        var elements = document.querySelectorAll('.'+ videoPlayerClass + ':not(.' + videoPlayerToggleBoundClass + ')');
        [].forEach.call(elements, function(element) {
            vl.videoPlayer.dress(element);
        });
    }


    /**
     * Initiate dressAll function
     */
    vl.videoPlayer.dressAll();
})();