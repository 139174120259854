/**
 * Progressively enhance an input field with a JS datepicker if possible
 */

vl.datepicker = {};
vl.datepicker.dress;
vl.datepicker.dressAll;
vl.datepicker.getDate;

// Keep supporting the older global vl.dressdatepicker function
vl.dressDatepicker = function() {
  var args = Array.prototype.slice.call(arguments).splice(1);
  var allArguments = args.concat(Array.prototype.slice.call(arguments));
  return vl.datepicker.dress.apply(this, allArguments);
};

(function () {

  var pickerFieldClass     = vl.ns + 'input-field--datepicker',
      pickerIconClass      = vl.ns + 'datepicker__icon',
      pickerBoundClass     = 'js-' + vl.ns + 'datepicker-bound',
      dateFormat           = 'DD.MM.YYYY',
      i18n = {
        previousMonth : 'Vorige maand',
        nextMonth     : 'Volgende maand',
        months        : ['januari','februari','maart','april','mei','juni','juli','augustus','september','oktober','november','december'],
        weekdays      : ['zondag','maandag','dinsdag','woensdag','donderdag','vrijdag','zaterdag'],
        weekdaysShort : ['zon','maa','din','woe','don','vri','zat']
      },
      pickerAtt             = 'data-' + vl.ns + 'datepicker',
      pickerMinAtt          = pickerAtt + '-min',
      pickerMaxAtt          = pickerAtt + '-max';

  vl.datepicker.dress = function(field) {
    addClass(field, pickerBoundClass);

    var picker = new Pikaday({
      field: field,
      format: dateFormat,
      i18n: i18n,
      firstDay: 1,
      minDate: moment(field.getAttribute(pickerMinAtt), dateFormat).toDate() || null,
      maxDate: moment(field.getAttribute(pickerMaxAtt), dateFormat).toDate() || null,
      yearRange: [moment(field.getAttribute(pickerMinAtt), dateFormat).year(), moment(field.getAttribute(pickerMaxAtt), dateFormat).year()],
      showDaysInNextAndPreviousMonths: true
    });

    // add datepicker class
    addClass(field, pickerFieldClass);
    // if datepicker does not have an ID, add one
    if(!field.id){
      field.id = uniqueId();
    }
    // add datepicker label after the field
    var label = document.createElement('label');
    label.setAttribute('for', field.id);
    addClass(label, pickerIconClass);
    field.parentNode.appendChild(label);
  };

  // create global function to get value from any datepicker field
  vl.datepicker.getDate = function(field) {
    return field.value;
  };

  vl.datepicker.dressAll = function() {

    var pickerFields = document.querySelectorAll('['+pickerAtt+']:not(.' + pickerBoundClass + ')');

    [].forEach.call(pickerFields, function(field) {
      vl.datepicker.dress(field);
    });
  };

  // Initiate
  vl.datepicker.dressAll();

})();
