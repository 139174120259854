
/**
 * Set some elements to the same height
 */

vl.equalheight = {};
vl.equalheight.resize;

(function () {

  var ehClass          = 'js-' + vl.ns + 'equal-height',
      ehContainerClass = 'js-' + vl.ns + 'equal-height-container',
      ehAtt            = 'data-' + vl.ns + 'equal-height';

  function sameHeights (container) {

    var nodeList = container.getElementsByClassName(ehClass);
    var elems = [].slice.call(nodeList);

    var tallest = Math.max.apply(Math, elems.map(function(elem, index) {
      elem.style.minHeight = ''; // clean first
      elem.style.height = ''; // clean first
      return elem.offsetHeight;
    }));

    tallest ++;

    elems.forEach(function(elem, index, arr){
      if(elem.hasAttribute(ehAtt) && elem.getAttribute(ehAtt) == "height"){
        elem.style.height = tallest + 'px';
      }else{
        elem.style.minHeight = tallest + 'px';
      }
    });

  }

  var resized = true;

  function fixContainers() {
    resized = true;

    // apply per container
    var containers = document.getElementsByClassName(ehContainerClass);

    [].forEach.call(containers, function (container) {
      sameHeights(container);
    });
  };

  vl.equalheight.resize = function(){
    if(resized) {
      requestAnimationFrame(fixContainers);
    }
    resized = false;
  };

  window.addEventListener('load', function() {
    window.addEventListener('resize', vl.equalheight.resize, false);
    vl.equalheight.resize();
  });
})();
